import React from 'react';

import { Grid, Row, Col, Button, FormGroup, FormControl } from 'react-bootstrap';

import instagram from './../../img/instagram@2x.png';
import facebook from './../../img/facebook@2x.png';
import twitter from './../../img/twitter@2x.png';
import linkedin from './../../img/linkedin@2x.png';

const SocialComponent = () => {
  return (
    <div className="news-letter">
      <Grid>
        <Col xs={12} sm={6} md={4}>
          <h1 className="section-heading">Social Media</h1>
          <Row>
            <Col xs={3} md={3}><a href="https://www.instagram.com/psm_mfg/"><img src={instagram} alt="instagram" className="img-responsive" /></a></Col>
            <Col xs={3} md={3}><a href="https://www.facebook.com/PrecisionScreenManufacturing"><img src={facebook} alt="facebook" className="img-responsive" /></a></Col>
            {/* <Col xs={3} md={3}><a href="https://twitter.com/psm_mfg"><img src={twitter} alt="twitter" className="img-responsive" /></a></Col> */}
            <Col xs={3} md={3}><a href="https://www.linkedin.com/company/precision-screen-manufacturing/"><img src={linkedin} alt="linkedin" className="img-responsive" /></a></Col>
          </Row>
        </Col>
        <Col xs={12} sm={6} md={8}>
          <div id="mc_embed_signup">
          <form action="https://psm-mfg.us15.list-manage.com/subscribe/post?u=ecea737eabd93d265712185eb&amp;id=0cddbb2a68" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <label for="mce-EMAIL">Newsletter Signup</label>
                <input type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required></input>
                <div style={{position: 'absolute', left: -5000}} aria-hidden="true">
                  <input type="text" name="b_ecea737eabd93d265712185eb_0cddbb2a68" tabindex="-1" value="" />
                </div>
                <div class="clear">
                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" />
                </div>
              </div>
          </form>
          </div>
        </Col>
      </Grid>
    </div>
  )
}

export default SocialComponent;