import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import * as routes from '../../constants/routes';

import ScrollToTop        from '../utilities/ScrollToTop'
import LandingPage from '../landing';
import SignUpPage from '../signUp';
import SignInPage from '../signIn';
import PasswordForgetPage from '../passwordForget';
import Dashboard from '../dashboard';
import NewProductView from '../products/NewProductView';
import AccountPage from '../account';
import withAuthentication from '../session/withAuthentication';
import RequestAccess from '../requestAccess';

// Top Level Pages
import Products           from '../products/Products'
import Industries         from '../industries/Industries'
import Blog               from '../blog/Blog'
import Contact            from '../contact/Contact'
import Webinar            from '../webinar/'
import PageNotFound       from '../utilities/PageNotFound'


//import AddPostPage        from '../components/blog/AddPostPage'
//import EditPostPage       from '../components/blog/EditPostPage'

// Industries Pages
import Aggregate          from '../industries/Aggregate'
import Architectural      from '../industries/Architectural'
import Industrial         from '../industries/Industrial'
import RefiningPetroChem  from '../industries/RefiningPetroChem'


// Webstore Pages
import WebStore                     from '../webStore/'
import WebStoreProductsDetails      from '../Shelf/ProductList/ProductDetails'

// Products Pages
import WovenWireMesh      from '../products/WovenWireMesh'
import WeldedWireMesh     from '../products/WeldedWireMesh'
import PerforatedMetal    from '../products/PerforatedMetal'
import TieWire            from '../products/TieWire'
import HardwareCloth      from '../products/HardwareCloth'
import FiberglassGrating  from '../products/FiberglassGrating'
import SafetyGrating      from '../products/SafetyGrating'
import ExpandedMetal      from '../products/ExpandedMetal'
import BarGrating         from '../products/BarGrating'
import ReactorInternals   from '../products/ReactorInternals'

import Downloads          from '../resources/Downloads'
import ShippingAndCrating from '../resources/ShippingAndCrating'
import Materials          from '../resources/Materials'
import Charts             from '../resources/Charts'
import CreditApplication  from '../resources/CreditApplication'
import Terms              from '../resources/Terms'

import About              from '../aboutUs/About'
import Jobs               from '../aboutUs/Jobs'

export const history = createHistory();

const App = () =>
  <Router>
    <ScrollToTop>
      <Switch>
        <div className="app">
          <Route exact path={routes.HOME}                     component={LandingPage} />
          <Route exact path={routes.SIGN_UP}                  component={SignUpPage} />
          <Route exact path={routes.SIGN_IN}                  component={SignInPage} />
          <Route exact path={routes.PASSWORD_FORGET}          component={PasswordForgetPage} />
          <Route exact path={routes.DASHBOARD}                component={Dashboard} />
          <Route exact path={routes.ACCOUNT}                  component={AccountPage} />
          <Route exact path={routes.REQUEST_ACCESS}           component={RequestAccess} />

          <Route exact path={routes.WEBSTORE}                 component={WebStore} />
          <Route exact path={routes.NEW_PRODUCT}              component={NewProductView} />
          <Route exact path={routes.WEBSTORE_PRODUCTS_DETAIL} component={WebStoreProductsDetails} />
          <Route exact path={routes.PRODUCTS}                 component={Products} />
          <Route exact path={routes.INDUSTRIES}               component={Industries} />
          <Route exact path={routes.BLOG}                     component={Blog} />
          <Route exact path={routes.CONTACT}                  component={Contact} />
          <Route exact path={routes.WEBINAR}                  component={Webinar} />
          <Route exact path={routes.PAGENOTFOUND}             component={PageNotFound} />

          <Route exact path={routes.AGGREGATE}                component={Aggregate} />
          <Route exact path={routes.ARCHITECTURAL}            component={Architectural} />
          <Route exact path={routes.INDUSTRIAL}               component={Industrial} />
          <Route exact path={routes.REFININGPETROCHEM}        component={RefiningPetroChem} />

          <Route exact path={routes.WOVENWIREMESH}            component={WovenWireMesh} />
          <Route exact path={routes.WELDEDWIREMESH}           component={WeldedWireMesh} />
          <Route exact path={routes.PERFORATEDMETAL}          component={PerforatedMetal} />
          <Route exact path={routes.TIEWIRE}                  component={TieWire} />
          <Route exact path={routes.HARDWARECLOTH}            component={HardwareCloth} />
          <Route exact path={routes.FIBERGLASSGRATING}        component={FiberglassGrating} />
          <Route exact path={routes.SAFETYGRATING}            component={SafetyGrating} />
          <Route exact path={routes.EXPANDEDMETAL}            component={ExpandedMetal} />
          <Route exact path={routes.BARGRATING}               component={BarGrating} />
          <Route exact path={routes.REACTORINTERNALS}         component={ReactorInternals} />

          <Route exact path={routes.DOWNLOADS}                component={Downloads} />
          <Route exact path={routes.SHIPPING}                 component={ShippingAndCrating} />
          <Route exact path={routes.MATERIALS}                component={Materials} />
          <Route exact path={routes.CHARTS}                   component={Charts} />
          <Route exact path={routes.CREDITAPPLICATION}        component={CreditApplication} />
          <Route exact path={routes.TERMS}                    component={Terms} />

          <Route exact path={routes.ABOUT}                    component={About} />
          <Route exact path={routes.JOBS}                     component={Jobs} />
        </div>
      </Switch>
    </ScrollToTop>
  </Router>

export default withAuthentication(App);