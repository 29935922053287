import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const About = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={12}>
              <h1>AboutUs</h1>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default About;