import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const prodConfig = {
  apiKey: "AIzaSyBxUnjvde59MNeNvDX8GZ0F5bhdwHOiU4c",
  authDomain: "psm-app-210718.firebaseapp.com",
  databaseURL: "https://psm-app-210718.firebaseio.com",
  projectId: "psm-app-210718",
  storageBucket: "psm-app-210718.appspot.com",
  messagingSenderId: "282383279975",
  appId: "1:282383279975:web:03a41c1834b8027431f1c2"
};

const devConfig = {
  apiKey: "AIzaSyBxUnjvde59MNeNvDX8GZ0F5bhdwHOiU4c",
  authDomain: "psm-app-210718.firebaseapp.com",
  databaseURL: "https://psm-app-210718.firebaseio.com",
  projectId: "psm-app-210718",
  storageBucket: "psm-app-210718.appspot.com",
  messagingSenderId: "282383279975",
  appId: "1:282383279975:web:03a41c1834b8027431f1c2"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const database = firebase.database();
const db = firebase.database();
const auth = firebase.auth();

export {
  db,
  auth,
  firebase,
  database as default 
};

