import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';



const Charts = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={12}>
              <h1>Charts</h1>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_WOVEN_MESH.pdf"
                    target="_blank" rel="noopener noreferrer">Download Woven Mesh Book</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_WELDED_WIRE_MESH.pdf"
                    target="_blank" rel="noopener noreferrer">Download Welded Wire Mesh Chart</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_U-CHANNEL_EDGING.pdf"
                    target="_blank" rel="noopener noreferrer">Download U-Channel Edging Chart</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_HARDWARE_CLOTH.pdf"
                    target="_blank" rel="noopener noreferrer">Download Hardware Cloth Chart</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_PERFORATED_METAL.pdf"
                    target="_blank" rel="noopener noreferrer">Download Perforated Metal Chart</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_EXPANDED_METAL.pdf"
                    target="_blank" rel="noopener noreferrer">Download Expanded Metal Chart</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_BAR_GRATING.pdf"
                    target="_blank" rel="noopener noreferrer">Download Bar Grating Chart</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_SAFETY_GRATING.pdf"
                    target="_blank" rel="noopener noreferrer">Download Safety Grating Chart</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_FIBERGLASS_GRATING.pdf"
                    target="_blank" rel="noopener noreferrer">Download Fiberglass Grating Charts</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_TIE_WIRE.pdf"
                    target="_blank" rel="noopener noreferrer">Download Tie Wire Chart</a>
              </div>
              <div className="well">
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_REACTOR_INTERNALS.pdf"
                    target="_blank" rel="noopener noreferrer">Download Reactor Internal Diagram</a>
              </div>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Charts;