import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { Grid, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';


// Product Page Style
import './../../styles/products-style.css';

// Charts Components
import TwoInchOpnToOneAndHalfOpn from './WovenWireMesh/TwoInchOpnToOneAndHalfOpn'
import OneAndHalfToOne from './WovenWireMesh/OneAndHalfToOne'
import OneInchMesh from './WovenWireMesh/OneInchMesh'
import ThreeFourthOfInchToHalfInch from './WovenWireMesh/ThreeFourthOfInchToHalfInch'
import HalfInchToThreeEightsOfInch from './WovenWireMesh/HalfInchToThreeEightsOfInch'
import ThreeEightsToThreeSixteenth from './WovenWireMesh/ThreeEightsToThreeSixteenth'
import ThreeSixteenthToOneEight from './WovenWireMesh/ThreeSixteenthToOneEight'
import TwoMesh from './WovenWireMesh/TwoMesh'
import TwoMeshToFourMesh from './WovenWireMesh/TwoMeshToFourMesh'
import FiveMeshToEightMesh from './WovenWireMesh/FiveMeshToEightMesh'
import EightMeshToSixTeenMesh from './WovenWireMesh/EightMeshToSixTeenMesh'
import SixTeenMeshToThirtyMesh from './WovenWireMesh/SixTeenMeshToThirtyMesh'
import ThirtyMeshToEightyMesh from './WovenWireMesh/ThirtyMeshToEightyMesh'
import EightyMeshToThreeTwentyFiveMesh from './WovenWireMesh/EightyMeshToThreeTwentyFiveMesh'
import HeroHeader from '../landing/HeroHeader';



const ChartsComponent = () => (
  <Router>
    <div>
      <Col md={2}>
        <h4>Select Mesh Size</h4>
        <ButtonToolbar>
          <NavLink to="/woven-wire-mesh/two-inch-opn-to-one-and-half-opn"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">2″OPN - 1 1/2″OPN</Button></NavLink>
          <NavLink to="/woven-wire-mesh/one-and-half-to-one" activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">1 1/2″OPN - 1″OPN</Button></NavLink>
          <NavLink to="/woven-wire-mesh/one-inch-mesh"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">1″ MESH</Button></NavLink>
          <NavLink to="/woven-wire-mesh/three-fourth-of-inch-to-half-inch"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">3/4″OPN - 1/2″OPN</Button></NavLink>
          <NavLink to="/woven-wire-mesh/half-inch-to-three-eights-of-inch"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">1/2″OPN - 3/8″OPN</Button></NavLink>
          <NavLink to="/woven-wire-mesh/three-eights-to-three-sixteenth"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">3/8″OPN - 3/16″OPN</Button></NavLink>
          <NavLink to="/woven-wire-mesh/three-sixteenth-to-one-eighth"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">3/16″OPN - 1/8″OPN</Button></NavLink>
          <NavLink to="/woven-wire-mesh/two-mesh"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">2 MESH</Button></NavLink>
          <NavLink to="/woven-wire-mesh/two-mesh-to-four-mesh"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">2 MESH - 4 MESH</Button></NavLink>
          <NavLink to="/woven-wire-mesh/five-mesh-to-eight-mesh"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">5 MESH - 8 MESH</Button></NavLink>
          <NavLink to="/woven-wire-mesh/eight-mesh-to-sixteen-mesh"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">8 MESH - 16 MESH</Button></NavLink>
          <NavLink to="/woven-wire-mesh/sixteen-mesh-to-thirty-mesh"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">16 MESH - 30 MESH</Button></NavLink>
          <NavLink to="/woven-wire-mesh/thirty-mesh-to-eighty-mesh"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">30 MESH - 80 MESH</Button></NavLink>
          <NavLink to="/woven-wire-mesh/eighty-mesh-to-three-twenty-five-mesh"  activeClassName="is-selected"><Button className="chart-selector-bt chart-bt">80 MESH - 325 MESH</Button></NavLink>
        </ButtonToolbar>
      </Col>
      <Col md={10}>
        <Route exact path="/woven-wire-mesh/two-inch-opn-to-one-and-half-opn" component={TwoInchOpnToOneAndHalfOpn} />
        <Route path="/woven-wire-mesh/one-and-half-to-one" component={OneAndHalfToOne} />
        <Route path="/woven-wire-mesh/one-inch-mesh" component={OneInchMesh} />
        <Route path="/woven-wire-mesh/three-fourth-of-inch-to-half-inch" component={ThreeFourthOfInchToHalfInch} />
        <Route path="/woven-wire-mesh/half-inch-to-three-eights-of-inch" component={HalfInchToThreeEightsOfInch} />
        <Route path="/woven-wire-mesh/three-eights-to-three-sixteenth" component={ThreeEightsToThreeSixteenth} />
        <Route path="/woven-wire-mesh/three-sixteenth-to-one-eighth" component={ThreeSixteenthToOneEight} />
        <Route path="/woven-wire-mesh/two-mesh" component={TwoMesh} />
        <Route path="/woven-wire-mesh/two-mesh-to-four-mesh" component={TwoMeshToFourMesh} />
        <Route path="/woven-wire-mesh/five-mesh-to-eight-mesh" component={FiveMeshToEightMesh} />
        <Route path="/woven-wire-mesh/eight-mesh-to-sixteen-mesh" component={EightMeshToSixTeenMesh} />
        <Route path="/woven-wire-mesh/sixteen-mesh-to-thirty-mesh" component={SixTeenMeshToThirtyMesh} />
        <Route path="/woven-wire-mesh/thirty-mesh-to-eighty-mesh" component={ThirtyMeshToEightyMesh} />
        <Route path="/woven-wire-mesh/eighty-mesh-to-three-twenty-five-mesh" component={EightyMeshToThreeTwentyFiveMesh} />
      </Col>
    </div>
  </Router>
);

// Instructionn Components
const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <h4>Measuring and Ordering Material</h4>
      <Col md={4} className="bg-light box-shadow"><p>1.Specify opening or mesh count, wire diameter and desired alloy.</p></Col>
      <Col md={4} className="bg-light box-shadow"><p>2.Specify overall dimensions of material, and quantity required.</p></Col>
      <Col md={4} className="bg-light box-shadow"><p>3.If ordering a slotted opening, the direction of the slot should be specified.</p></Col>
    </div>
  )
}

const OurCapabilities = () => {
  return (
    <div>
      <h3>Our Capabilities</h3>
      <p>We maintain a large inventory on many opens and wire diameters.
         We also have the capabilities to weave all grades of stainless steel,
         most exotic alloys for the refining/petrochem industry,
         galvanized wire, aluminum, and other products used for separating and screening.
         We strive on the fastest lead times in the industry at competitive prices.
         Our weaving capabilities include lock crimp, double crimp, and also smooth top crimp.
         We also produce a wide range of weave patterns including square mesh, rectangular, and slotted openings.
         We are able to weave a wide variety of openings from 10 mesh to 4″ square openings with a wide range of wire diameter.
         We would appreciate the opportunity to quote any requirements you may have.
    </p>
    </div>
  )
}

const WovenWireMesh = () => {
  return (
    <div>
      <Layout>
        <HeroHeader/>
        {/* <Grid fluid>
          <div className="woven-wire-mesh-header">
            <Grid><h2>Woven Wire Mesh</h2>
              <h4>Wire cloth/screen manufacturer with <br />weaving facilities in San Antonio and Tulsa</h4>
              <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_WOVEN_MESH.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
            </Grid>
          </div>
        </Grid> */}
        <Grid>
          <Row className="show-grid">
            <MeasuringAndOrderingInstructions />
            <hr />
            <ChartsComponent />
          </Row>
          <OurCapabilities />
        </Grid>
      </Layout>
    </div>
  )
}

export default WovenWireMesh;