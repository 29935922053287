import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { Grid, Col, Button } from "react-bootstrap";

import psm_video from "./../../img/psm.mp4";

const HeroHeader = () => {
  return (
    <div className="hero-header-outter">
      <Grid fluid>
        <div className="hero-video-wrap">
          <ReactPlayer
            className="video-js"
            url={psm_video}
            playing
            muted
            loop={true}
          />
        </div>
        <div className="container">
          <div className="header-video-content-wrapper">
          
            <Col className="hero-type-container" md={12} sm={12}>
              
              {/* <h1 className="header-type-h1">Woven Wire Mesh</h1> */}
              <h3 className="header-type-h2">
                Largest weaving capabilities in North America.
              </h3>
              
                <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_WOVEN_MESH.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
              
            </Col>


          </div>
        </div>
      </Grid>
    </div>
  );
};

export default HeroHeader;
