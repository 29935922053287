import React from 'react';
import { connect } from 'react-redux';
import Shelf from '../Shelf';
import Filter from '../Shelf/Filter';
import FloatCart from '../FloatCart';
import Layout from '../layout/Layout';
import { Grid } from 'react-bootstrap';
import '../../styles/index.scss';
import '../Shelf/style.scss';
import StoreHeader from './StoreHeader'


const WebStore = (props) => (
  <Layout>
    <StoreHeader/>
    <Grid>
      <React.Fragment>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="col-md-3 col-sm-12 col-xs-12">
            <Filter />
          </div>
          <div className="col-md-9 col-sm-12 col-xs-12">
            <Shelf />
          </div>
        </div>


        {props.authUser ? (
          <FloatCart/>
          ) : (
            <div/>
            )}
      </React.Fragment>
    </Grid>
  </Layout>
);

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
});

export default connect(
  mapStateToProps,
  { undefined }
)(WebStore);
