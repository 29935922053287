import React from 'react';

import woven_wire_mesh from '../../img/woven_wire@2x.png';
import welded_wire_mesh from '../../img/welded_wire@2x.png';
import perforated_metal from '../../img/perforated_metal@2x.png';
import tie_wire from '../../img/tie_wire@2x.png';
import hardware_cloth from '../../img/hardware_cloth@2x.png';
import fiberglass_grating from '../../img/fiberglass_grating@2x.png';
import safety_grating from '../../img/safety_grating@2x.png';
import expanded_metal from '../../img/expanded_metal@2x.png';
import bar_grating from '../../img/bar_grating@2x.png';

import { Grid, Row, Col } from 'react-bootstrap';
import Layout from '../layout/Layout';

const Products = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <p>Lots of products will go here!</p>
            <Col xs={12} md={4}>
              <code>Woven Mesh</code>
              <img src={woven_wire_mesh} className="img-responsive" draggable="false" alt="Woven Wire Mesh" />
            </Col>
            <Col xs={12} md={4}>
              <code>Welded Wire Mesh</code>
              <img src={welded_wire_mesh} className="img-responsive" draggable="false" alt="Welded Wire Mesh" />
            </Col>
            <Col xs={12} md={4}>
              <code>Perdorated Mesh</code>
              <img src={perforated_metal} className="img-responsive" draggable="false" alt="Perforated Metal" />
            </Col>
          </Row>
          <Row className="show-grid">
            <Col xs={12} md={4}>
              <code>Tie Wire</code>
              <img src={tie_wire} className="img-responsive" draggable="false" alt="Tie Wire" />
            </Col>
            <Col xs={12} md={4}>
              <code>Hardware Cloth</code>
              <img src={hardware_cloth} className="img-responsive" draggable="false" alt="Hardware Cloth" />
            </Col>
            <Col xs={12} md={4}>
              <code>Fiberglass Grating</code>
              <img src={fiberglass_grating} className="img-responsive" draggable="false" alt="Fiberglass Grating" />
            </Col>
          </Row>
          <Row className="show-grid">
            <Col xs={12} md={4}>
              <code>Safety Grating</code>
              <img src={safety_grating} className="img-responsive" draggable="false" alt="Safety Grating" />
            </Col>
            <Col xs={12} md={4}>
              <code>Expanded Metal</code>
              <img src={expanded_metal} className="img-responsive" draggable="false" alt="Expanded Metal" />
            </Col>
            <Col xs={12} md={4}>
              <code>Bar Grating</code>
              <img src={bar_grating} className="img-responsive" draggable="false" alt="Bar Grating" />
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Products;