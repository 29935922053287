import React from 'react';
import { Link } from "react-router-dom"

import { Grid, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <div className="footer">
      <Grid>
        <Row>
          <Col xs={12} sm={4} md={2}>
            <h3>Products</h3>
            <Link to="/woven-wire-mesh/two-inch-opn-to-one-and-half-opn"><p>Woven Wire Mesh</p></Link>
            <Link to="/welded-wire-mesh"><p>Welded Wire Mesh</p></Link>
            <Link to="/perforated-metal"><p>Perforated Metal</p></Link>
            <Link to="/tie-wire"><p>Tie Wire</p></Link>
            <Link to="/hardware-cloth"><p>Hardware Cloth</p></Link>
            <Link to="/fiberglass-grating"><p>Fiberglass Grating</p></Link>
            <Link to="/safety-grating"><p>Safety Grating</p></Link>
            <Link to="/expanded-metal"><p>Expanded Metal</p></Link>
            <Link to="/bar-grating"><p>Bar Grating</p></Link>
          </Col>
          <Col xs={12} sm={4} md={2}>
            <h3>Industries</h3>
            <Link to="/aggregate"><p>Aggregate</p></Link>
            <Link to="/architectural"><p>Architectural</p></Link>
            <Link to="/industrial"><p>Industrial</p></Link>
            <Link to="/refining-petro-chem"><p>Refining/Petro-Chem</p></Link>
          </Col>
          <Col xs={12} sm={4} md={2}>
            <h3>Resources</h3>
            <Link to="/downloads"><p>Downloads</p></Link>
            <Link to="/shipping-and-crating"><p>Shipping & Crating</p></Link>
            <Link to="/materials"><p>Materials</p></Link>
            <Link to="/charts"><p>Charts</p></Link>
            <Link to="/credit-application"><p>Credit Application</p></Link>
          </Col>
          <Col xs={12} sm={4} md={2}>
            <h3>About Us</h3>
            <Link to="/about-us"><p>About</p></Link>
            <Link to="/blog"><p>News</p></Link>
            <Link to="/jobs"><p>Jobs</p></Link>
          </Col>
          <Col xs={12} sm={4} md={2}>
            <h3>Contact Us</h3>
            <Link to="/email"><p>Email</p></Link>
            <Link to="/call"><p>Call: (866) 596-7537</p></Link>
          </Col>
        </Row>
        <Row>
          <p>© 2024 🇺🇸 Precision Screen Manufacturing. All rights reserved. <Link to="/terms">Terms</Link></p>
        </Row>
      </Grid>
    </div>
  )
}

export default Footer;