import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const CreditApplication = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={12}>
              <h1>Credit Application</h1>
              <div className="well">
                <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_CREDIT_APPLICATION.pdf">Download Credit Application</a>
              </div>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div >
  )
}

export default CreditApplication;