import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { startUserProps } from '../../actions/user';
import { Grid } from 'react-bootstrap';

import withAuthorization from '../session/withAuthorization';
import { db } from '../../firebase';
import SignOutButton from '../signOut';
import Layout from '../layout/Layout';

class Dashboard extends Component {

  componentDidMount() {
    this.props.startUserProps()
  }

  render() {
    const { name, company, email, uid, city = 'null' } = this.props.user
    return (
      <Layout>
        <Grid>
          <h1>Dashboard</h1>
          <p>{name} {uid}</p>
          <p>{email}</p>
          <p>{company}</p>
          <p>{city}</p>

          <SignOutButton />
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  startUserProps: () => dispatch(startUserProps()),
});

const authCondition = (authUser) => !!authUser;

export default compose( withAuthorization(authCondition), connect(mapStateToProps, mapDispatchToProps))(Dashboard);
