import React from 'react';
import { connect } from "react-redux"
import { Grid } from 'react-bootstrap';
import Layout from '../layout/Layout';
// import BlogPost from './BlogPost'
// import AddPostPage from './AddPostPage';

 
const Blog = props =>
  <Layout>
    <Grid>
      <h1>Announcements</h1>
        {/* {props.posts.map( post => <BlogPost key={post.id} {...post} /> )} */}
        <p>Blog posts coming soon</p>
    </Grid>
  </Layout>
  

// const mapStateToProps = (state, props) => ({
//   posts: state.posts
// });

export default connect(
  // mapStateToProps,
  undefined
)(Blog);
