import React from 'react';
import { Col } from "react-bootstrap";
import Product from './Product';
import _ from 'lodash';
const ProductList = ({ products }) => {
  return _.reverse(_.toArray(products)).map(p => (
    <Col md={4} key={p.id}>
      <Product product={p} />
    </Col>
    )
  );
};

export default ProductList;
