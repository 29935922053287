import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Button } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';

const Materials = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="materials-header">
            <Grid>
              <h2>Terms</h2>
              <h4>We care about privacy.</h4>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
          <div class="container">
<b>Precision Screen Manufacturing Terms of Service</b>

<p>Welcome to PSM!</p>

<p>Thanks for using our products and services. The Service and product are provided by Precision Screen Manufacturing (“PSM”), located at 7287 E HWY 88 Oologah, Oklahoma 74053

By using our Services, you are agreeing to these terms. Please read them carefully.</p>

<strong>Using our Services</strong>
<p>You must follow any policies made available to you within the Services.

Don’t misuse our Services. For example, don’t interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.

Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services.

Our Services display some content that is not PSM’s. This content is the sole responsibility of the entity that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don’t assume that we do.

In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.

Some of our Services are available on mobile devices. Do not use such Services in a way that distracts you and prevents you from obeying traffic or safety laws.</p>

<strong>Your PSM Account</strong>
<p>You may need a PSM Account in order to use some of our Services. You may create your own PSM Account, or your PSM Account may be assigned to you by an administrator, such as your employer. If you are using a PSM Account assigned to you by an administrator, different or additional terms may apply and your administrator may be able to access or disable your account.

To protect your PSM Account, keep your password confidential. You are responsible for the activity that happens on or through your PSM Account. Try not to reuse your PSM Account password on third-party applications. If you learn of any unauthorized use of your password or PSM Account, notify PSM.</p>

<strong>Privacy and Copyright Protection</strong>
<p>PSM’s privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that PSM can use such data in accordance with our privacy policies.

We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act.

We provide information to help copyright holders manage their intellectual property online. If you think somebody is violating your copyrights and want to notify us, you can find information about submitting notices and PSM’s policy about responding to notices in our Contact page.</p>

<strong>Your Content in our Services</strong>
<p>Some of our Services allow you to upload, submit, store, send or receive content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours.

When you upload, submit, store, send or receive content to or through our Services, you give PSM (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with our Services), communicate, publish, publicly perform, publicly display and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Services, and to develop new ones. This license continues even if you stop using our Services. Some Services may offer you ways to access and remove content that has been provided to that Service. Also, in some of our Services, there are terms or settings that narrow the scope of our use of the content submitted in those Services. Make sure you have the necessary rights to grant us this license for any content that you submit to our Services.

Our automated systems analyze your content (including emails) to provide you personally relevant product features, such as customized search results, tailored advertising, and spam and malware detection. This analysis occurs as the content is sent, received, and when it is stored.

If you have a PSM Account, we may display your Profile name, Profile photo, and actions you take on PSM or on third-party applications connected to your PSM Account in our Services, including displaying in ads and other commercial contexts. We will respect the choices you make to limit sharing or visibility settings in your PSM Account.

You can find more information about how PSM uses and stores content in the privacy policy or additional terms for particular Services. If you submit feedback or suggestions about our Services, we may use your feedback or suggestions without obligation to you.</p>

<strong>Modifying and Terminating our Services</strong>
<p>We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.

You can stop using our Services at any time, although we’ll be sorry to see you go. PSM may also stop providing Services to you, or add or create new limits to our Services at any time.

We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service.</p>

<strong>Our Warranties and Disclaimers</strong>
<p>We provide our Services using a commercially reasonable level of skill and care and we hope that you will enjoy using them. But there are certain things that we don’t promise about our Services.

OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER PSM NOR ITS SUPPLIERS OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SERVICES “AS IS”.

SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.</p>

<strong>Liability for our Services</strong>
<p>WHEN PERMITTED BY LAW, PSM, AND PSM’S SUPPLIERS AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.

TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF PSM, AND ITS SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN).

IN ALL CASES, PSM, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.</p>

<strong>Business uses of our Services</strong>
<p>If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify PSM and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.</p>

<strong>About these Terms</strong>
<p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We’ll post notice of modifications to these terms on this page. We’ll post notice of modified additional terms in the applicable Service. Changes will not apply retroactively and will become effective no sooner than fourteen days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service.</p>

<strong>Newsletter Sign up</strong>
<p>By sending a message using our contact form your will be added to our monthly newsletter campaigns. User is welcome to unsubscribe at any time.

If there is a conflict between these terms and the additional terms, the additional terms will control for that conflict.

These terms control the relationship between PSM and you. They do not create any third party beneficiary rights.

If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future).

If it turns out that a particular term is not enforceable, this will not affect any other terms.

For information about how to contact PSM, please visit our contact page.Using our Services
You must follow any policies made available to you within the Services.</p>

Thank you!

</div>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Materials;