import { FETCH_PRODUCTS } from './actionTypes';
import database from "../../firebase/firebase";
import _ from 'lodash';

// import { productsAPI } from '../util';

const compare = {
  lowestprice: (a, b) => {
    if (a.price < b.price) return -1;
    if (a.price > b.price) return 1;
    return 0;
  },
  highestprice: (a, b) => {
    if (a.price > b.price) return -1;
    if (a.price < b.price) return 1;
    return 0;
  }
};

export const fetchProducts = (filters, sortBy, callback ) => dispatch => {
    
    return database.ref(`/products/`).once("value")
    .then(snapshot => {
      
      let products = _.toArray(snapshot.val())

      if (!!filters && filters.length > 0) {
        products = products.filter(p => filters.find(f => p.productCategories.find(size => size === f)));
      }

      if (!!sortBy) {
        products = products.sort(compare[sortBy]);
      }

      if (!!callback) {
        callback();
      }

      return dispatch({
        type: FETCH_PRODUCTS,
        payload: products
      });
      
    })
    .catch(err => {
      console.log(err, 'Could not fetch products. Try again later. form dash');
    });
};



// import { FETCH_PRODUCTS } from './actionTypes';
// import axios from 'axios';

// import { productsAPI } from '../util';

// const compare = {
//   lowestprice: (a, b) => {
//     if (a.price < b.price) return -1;
//     if (a.price > b.price) return 1;
//     return 0;
//   },
//   highestprice: (a, b) => {
//     if (a.price > b.price) return -1;
//     if (a.price < b.price) return 1;
//     return 0;
//   }
// };

// export const fetchProducts = (filters, sortBy, callback) => dispatch => {
//   return axios
//     .get(productsAPI)
//     .then(res => {
//       let { products } = res.data;

//       if (!!filters && filters.length > 0) {
//         products = products.filter(p =>
//           filters.find(f => p.productCategories.find(size => size === f))
//         );
//       }

//       if (!!sortBy) {
//         products = products.sort(compare[sortBy]);
//       }

//       if (!!callback) {
//         callback();
//       }

//       return dispatch({
//         type: FETCH_PRODUCTS,
//         payload: products
//       });
//     })
//     .catch(err => {
//       console.log('Could not fetch products. Try again later.');
//     });
// };
