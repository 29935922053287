import '../../styles/App.css';
import React, { Component } from "react";
import * as THREE from "three";

const SEPARATION = 100, AMOUNTX = 100, AMOUNTY = 100;
let count = 0;
let mouseX = 0, mouseY = 0;

let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

var angle = 0;
var radius = 2000; 

class Landing extends Component {

  componentDidMount() {
      
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2(0x0f162c, 0.0006);

    // Bottom Grid Floor
    var Bottom__grid = new THREE.GridHelper(5000, 60);
    Bottom__grid.position.y = -50;
    this.scene.add(Bottom__grid);

    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: false });
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize(width, height);
    this.renderer.setClearColor(this.scene.fog.color, 0.1)
    this.mount.appendChild(this.renderer.domElement);
    this.mount.style.touchAction = 'none';
    this.mount.addEventListener( 'pointermove', this.onPointerMove ); 
    
    //

    window.addEventListener( 'resize', this.onWindowResize, false );

    //add Camera
    this.camera = new THREE.PerspectiveCamera( 90, width / height, 1, 20000 );
    this.camera.position.set(0, 100, 100);
   
    this.addParticles();
    // this.addEarth();
    this.renderScene();
    
    //start animation
    this.start();
  }

  addEarth(){
    
    const ballMat = new THREE.MeshStandardMaterial( {
      color: 0x212121,
      roughness: 1,
      metalness: 1.0
    } );


    // Earth
    const ballGeometry = new THREE.SphereBufferGeometry( 10, 32, 32 );
    const ballMesh = new THREE.Mesh( ballGeometry, ballMat );
      ballMesh.position.set( 0, 15, 10 );
      ballMesh.rotation.y = Math.PI;
      ballMesh.castShadow = true;
      this.scene.add( ballMesh );


      var sphere = new THREE.SphereBufferGeometry( 3, 80, 80 );
      
      sphere.receiveShadow = true;
      sphere.castShadow = true;
    
    
      // RED LIGHT
      var light11 = new THREE.PointLight( 0xff0040, 300, 250 );
      light11.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xff0040 } ) ) );
      light11.receiveShadow = true;
      light11.castShadow = true;
      light11.position.y = 60
      light11.position.x = 60
      this.scene.add( light11 );
  
        // BLUE LIGHT 2
      var light2 = new THREE.PointLight( 0x0040ff, 1300, 250 );
      light2.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x0040ff } ) ) );
      light2.receiveShadow = true;
      light2.castShadow = true;
      light2.position.y = 10
      light2.position.x = 60
      this.scene.add( light2 );
    
      
    }

  addParticles(){
    

    const numParticles = AMOUNTX * AMOUNTY;

    const positions = new Float32Array( numParticles * 3 );
    const scales = new Float32Array( numParticles );

    let i = 0, j = 0;

    for ( let ix = 0; ix < AMOUNTX; ix ++ ) {

      for ( let iy = 0; iy < AMOUNTY; iy ++ ) {

        positions[ i ] = ix * SEPARATION - ( ( AMOUNTX * SEPARATION ) / 3 ); // x
        positions[ i + 1 ] = 0; // y
        positions[ i + 2 ] = iy * SEPARATION - ( ( AMOUNTY * SEPARATION ) / 3 ); // z

        scales[ j ] = 1;

        i += 3;
        j ++;

      }

    }

    const material_ = new THREE.LineBasicMaterial( { color: 0xFFFFFF, side: 'both' } );

    const points = [];
    // points.push( new THREE.Vector3( - 10, 0, 0 ) );
    // points.push( new THREE.Vector3( 0, 0, 0 ) );
    // points.push( new THREE.Vector3( 10, 0, 0 ) );

    const geometry = new THREE.BufferGeometry().setFromPoints( points );

    
    // const geometry = new THREE.BufferGeometry();

    geometry.setAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
    geometry.setAttribute( 'scale', new THREE.BufferAttribute( scales, 3 ) );


    const material = new THREE.ShaderMaterial( {
      uniforms: {
        // color: { value: new THREE.Color( 0x311B92 ) },
        color: { value: new THREE.Color( 0xFFFFFF ) },
      },
      vertexShader: document.getElementById( 'vertexshader' ).textContent,
      fragmentShader: document.getElementById( 'fragmentshader' ).textContent
    } );
    
    this.particles = new THREE.Line( geometry, material_, 2 );


    this.particles.castShadow = true;
    // this.particles.receiveShadow = true;
    
    this.scene.add( this.particles );
  }

  

  onWindowResize = () => {

    windowHalfX = window.innerWidth / 2;
    windowHalfY = window.innerHeight / 2;

    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize( window.innerWidth, window.innerHeight );

  }

  onPointerMove = ( event ) => {

    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;

  }
  
  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    
    this.camera.position.x += ( mouseX - this.camera.position.x ) * .05;
    this.camera.position.y += ( - mouseY - this.camera.position.y ) * .05;
    
    this.camera.position.x = radius * Math.cos( angle );  
    this.camera.position.z = radius * Math.sin( angle );
    angle += 0.001;
    
    this.camera.lookAt( this.scene.position );

    const positions = this.particles.geometry.attributes.position.array;
    const scales = this.particles.geometry.attributes.scale.array;

    let i = 0, j = 0;

    for ( let ix = 0; ix < AMOUNTX; ix ++ ) {

      for ( let iy = 0; iy < AMOUNTY; iy ++ ) {

        positions[ i + 1 ] = ( Math.sin( ( ix + count ) * 0.3 ) * 50 ) +
                ( Math.sin( ( iy + count ) * 0.5 ) * 50 );

        scales[ j ] = ( Math.sin( ( ix + count ) * 0.3 ) + 1 ) * 20 +
                ( Math.sin( ( iy + count ) * 0.5 ) + 1 ) * 20;

        i += 3;
        j ++;

      }

    }

    this.particles.geometry.attributes.position.needsUpdate = true;
    this.particles.geometry.attributes.scale.needsUpdate = true;

    count += 0.1;
    
    this.frameId = window.requestAnimationFrame(this.animate);
    this.renderScene();
  };
  
  renderScene = () => {
    if (this.renderer) {

      this.renderer.render(this.scene, this.camera);
    }
  };

  render() {
    return (
      <div>
      <div
        style={{ width: "100%", zIndex: -1, backgroundColor: 'rgb(1,2,4)' }}
        ref={mount => {
          this.mount = mount;
        }}
        />
        <div className="overlay"></div>
      </div>
    );
  }
}
export default Landing;
