import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import { Checkbox } from 'react-bootstrap';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var product_type = [
  { product_type: 'Select product type', product_code: 'DEFAULT' },
  { product_type: 'Woven Wire Mesh', product_code: 'WWM' },
  { product_type: 'Welded Wire Mesh', product_code: 'WM' },
  { product_type: 'Perforated Metal', product_code: 'PM' },
  { product_type: 'Tie Wire', product_code: 'TW' },
  { product_type: 'Hardware Cloth', product_code: 'HC' },
  { product_type: 'Fiberglass Grating', product_code: 'FG' },
  { product_type: 'Safety Grating', product_code: 'SG' },
  { product_type: 'Expanded Metal', product_code: 'EM' },
  { product_type: 'Bar Grating', product_code: 'OTHER' },
  { product_type: 'Deals', product_code: 'DEALS' },
  { product_type: 'Other', product_code: 'OTHER' }
];

class ProductForm extends React.Component {

    fileObj = [];
    fileArray = [];

  constructor(props) {
    super(props);

    this.state = {
      createdAt: props.product ? moment(props.product.createdAt) : moment(),
      displayName: this.props.user.displayName,
      city: this.props.user.city,
      photoURL: this.props.user.photoURL,
      productNotes: this.props.user.productNotes,
      productName: props.product ? props.product.productName : '',
      productAlloy: props.product ? props.product.productAlloy : '',
      productOpn: props.product ? props.product.productOpn : '',
      productWire: props.product ? props.product.productWire : '',
      productDims: props.product ? props.product.productDims : '',
      price: props.product ? props.product.price : 0,
      manufacturer: props.product ? props.product.manufacturer : '',
      hidden: props.product ? props.product.hidden : false,
      id: firebase.database().ref().push().key,
      uid: firebase.auth().currentUser.uid,
      productCategories: product_type,
      product_type_name: '',
      img_url: '',
      file: [null],
      isUploading: false,
      uploadProgress: 0,
      quantity: 1,
      error: ''
    };
  }

  // Product Type
  onCategoryChange = (e) => {
    this.setState(() => {
      return {
        type: e
      };
    })
  };

  // Product Name
  onProductNameChange = (e) => {
    const productName = e.target.value;
    this.setState(() => ({ productName }));
  };
  
  // Product Description / Notes
  onDescriptionChange = (e) => {
    const productNotes = e.target.value;
    this.setState(() => ({ productNotes }));
  };

  // Product Alloy
  onProductAlloyChange = (e) => {
    const productAlloy = e.target.value;
    this.setState(() => ({ productAlloy }));
  };
  
  // Product OPN
  onProductOpnChange = (e) => {
    const productOpn = e.target.value;
    this.setState(() => ({ productOpn }));
  };
  
  // Product Wire
  onProductWireChange = (e) => {
    const productWire = e.target.value;
    this.setState(() => ({ productWire }));
  };
  
  // Product Dims
  onProductDimsChange = (e) => {
    const productDims = e.target.value;
    this.setState(() => ({ productDims }));
  };
  
  // Product Manufacturer 
  onProductManufacturerChange = (e) => {
    const productManufacturer = e.target.value;
    this.setState(() => ({ manufacturer: productManufacturer }));
  };
  
  // Product Tags
  onTagChange = (e) => {
    this.setState((prevState) => {
      return {
        tags: prevState.tags.concat(e)
      };
    })
  };

  // Product Hidden
  onHiddenChange = () => {
    if(this.state.hidden === true){
      this.setState(() => ({ hidden: false}))
    }
    this.setState(() => ({ hidden: true}))
  }
    
  // Product Price Range
  onProductPriceChange = (e) => {
    const price = e.target.value;
    this.setState(() => ({ price: price }));
  };

  
  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.productName) {
      this.setState(() => ({ error: 'Please provide product name.' }));
    } else {
      this.setState(() => ({ error: '' }));
      this.props.onSubmit({
        id: this.state.id,
        displayName: this.props.user.displayName,
        slug: this.props.user.slug,
        city: this.props.user.city,
        productCategories: [this.state.product_type_name],
        product_type_name: this.state.product_type_name,
        productName: this.state.productName,
        productNotes: this.state.productNotes,
        productAlloy: this.state.productAlloy,
        productOpn: this.state.productOpn,
        productWire: this.state.productWire,
        productDims: this.state.productDims,
        hidden: this.state.hidden,
        price: Number(this.state.price),
        img_url: this.state.img_url,
        createdAt: this.state.createdAt.valueOf(),
        uid: this.state.uid,
        photoURL: this.props.user.photoURL,
        quantity: this.state.quantity,
      });
    }
    toast("Product successfully posted");
  };

// UPLOAD CODE

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });
 
  handleProgress = progress =>
    this.setState({
      uploadProgress: progress
    });
 
  handleUploadError = error => {
    this.setState({
      isUploading: false
      // Todo: handle error
    });
    console.error(error);
  };
 
  handleUploadSuccess = filename => {
    this.setState({ img_url: filename, progress: 100, isUploading: false });
    firebase
      .storage().ref(`/products/${this.state.id}/`)
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ img_url: url }));
  };

  onProductChange = (e) => {
    const product_type_name = e.target.value;
    this.setState(() => ({ product_type_name }));
  };

  render() {
    return (
      <div className="container-fluid">
      <form onSubmit={this.onSubmit}>
        {this.state.error && <p className="form__error">{this.state.error}</p>}
        
        <div className="col-md-9">
        <div className="row">
        

        <div className="form-group">
          <div className='col-md-12 col-sm-12 col-xs-12'>
          <h4>Add Product</h4>
          <br/>            
            <div>
              <div><h4>Product Type</h4></div>
              <div><p>Select one category type</p></div>
              <div className="new-post-cat-container">
                <div className="new-post-cat-inner-wrapper">
                  <div className="product_tag_container">
                    <div class="input-group input-group-lg  dropdown-menu-right">
                      <div className="select-container">
                        <select className="select-css" value={this.state.product_type_name} onChange={this.onProductChange}>
                          {this.state.productCategories.map(item => (
                            <option key={item.product_code} value={item.product_type} >
                              {item.product_type}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div><h4>Product Details</h4></div>
              <div><p>Enter information</p></div>
              <div className="new-post-cat-container">

              <div style={{ width: '100%'}}>
                <input
                  className="product_form_price_input"
                  type="text"
                  placeholder="Product name"
                  value={this.state.productName}
                  onChange={this.onProductNameChange} />
              </div>

              <div style={{ width: '100%', marginTop: 16, marginBottom: 16}}>
                <input
                  className="product_form_price_input"
                  type="text"
                  placeholder="Product notes"
                  value={this.state.productNotes}
                  onChange={this.onDescriptionChange}
                  row={4} />
              </div>
              
              <div style={{ width: '100%', marginBottom: 16, display: 'flex', flexDirection: 'row'}}>
              <div style={{ width: '100%', marginRight: 8}}>
                <input
                  className="product_form_price_input"
                  type="text"
                  placeholder="Alloy"
                  value={this.state.productAlloy}
                  onChange={this.onProductAlloyChange}
                  row={4} />
                  </div>
                  <div style={{ width: '100%', marginLeft: 8}}>
                <input
                  className="product_form_price_input"
                  type="text"
                  placeholder="OPN"
                  value={this.state.productOpn}
                  onChange={this.onProductOpnChange}
                  row={4} />
                  </div>
              </div>
              
              <div style={{ width: '100%', marginBottom: 16, display: 'flex', flexDirection: 'row'}}>
              <div style={{ width: '100%', marginRight: 8}}>
                <input
                  className="product_form_price_input"
                  type="text"
                  placeholder="Wire"
                  value={this.state.productWire}
                  onChange={this.onProductWireChange}
                  row={4} />
                  </div>
                  <div style={{ width: '100%', marginLeft: 8}}>
                <input
                  className="product_form_price_input"
                  type="text"
                  placeholder="Dims"
                  value={this.state.productDims}
                  onChange={this.onProductDimsChange}
                  row={4} />
                  </div>
                </div>
              </div>
            </div>
            
            <div>
            <div><h4>Price Range</h4></div>
            <div><p>Product price range. For product display purposes</p></div>
            <div className="new-post-cat-container">
              <div>
                <input
                  className="product_form_price_input"
                  type="text"
                  placeholder="Price"
                  value={this.state.price}
                  onChange={this.onProductPriceChange}
                  />
                </div>
              </div>            
            </div>

            <div>
              <div><h4>Select Image</h4></div>
              <div><p>Upload a pretty picture</p></div>
              <div className="new-post-cat-container">
              <div className="new-post-cat-inner-wrapper">
              <FileUploader
                accept="image/*"
                name="image-uploader-multiple"
                storageRef={firebase.storage().ref(`/products/${this.state.id}/`)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
              <p>Progress: {this.state.uploadProgress}</p>
            </div>
            </div>
            </div>

            <div>
              <div><h4>Hide Product</h4></div>
              <div><p>Temporarily hide product when unavailable from storefront</p></div>
              <div className="new-post-cat-container">
                <div style={{ marginBottom: 16, marginTop: 16, display:'flex', flexDirection: 'row',}}>
                  <div className="product_tag_container">
                    <div>
                      <Checkbox 
                        onClick={() => this.onHiddenChange()}/></div>
                        <div><p>Hidden</p></div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button type="button" onClick={this.onSubmit} className="btn btn-primary btn-lg">Post Product</button>
            </div>

          </div> 
        </div>

        </div>



        </div>
        
        <div className="col-md-3">
          <h4>Preview</h4>
          <div style={{marginTop: 16}}>
          <div className="list-card-style">

            <div style={{maxHeight: 180, overflow: 'hidden',   position: 'relative'}}>

              <div style={{  borderRadius: 16, minHeight: 220, overflow: 'hidden'}}>
                {this.state.img_url ? (
                  <img src={this.state.img_url} alt="product" style={{height: '100%', width: '100%' }} />
                  ) : ( <div></div>)}
              </div>

            </div>

            <div className="list-card" style={{flexDirection: 'row', minHeight: 120,}}>
              <div style={{}}>
                <div style={{padding: 4}}>
                  <p style={{fontSize: 12, fontWeight: 'bold'}}>{_.toUpper(this.state.product_type_name)}</p>
                  <p style={{fontSize: 18, fontWeight: '900'}}>{this.state.productName}</p>
                  <p style={{fontSize: 12}}>{this.state.productNotes}</p>

                  <div style={{display: 'flex', flexDirection: 'row',}}>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {this.state.productAlloy ? (
                      <>
                        <p style={{color: '#212121', fontWeight: 'bold'}}>Alloy</p><strong style={{color: '#999999', fontWeight: 900}}>{this.state.productAlloy}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {this.state.productOpn ? (
                      <>
                        <p style={{color: '#212121', fontWeight: 'bold'}}>Opn</p><strong style={{color: '#999999', fontWeight: 900}}>{this.state.productOpn}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {this.state.productWire ? (
                      <>
                        <p style={{color: '#212121', fontWeight: 'bold'}}>Wire</p><strong style={{color: '#999999', fontWeight: 900}}>{this.state.productWire}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {this.state.productDims ? (
                      <>
                        <p style={{color: '#212121', fontWeight: 'bold'}}>Dims</p><strong style={{color: '#999999', fontWeight: 900}}>{this.state.productDims}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
              </div>

                  <div>
                    <div className="val">
                      <p style={{fontSize: 12}}> {this.state.currencyFormat} {this.state.price}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          </div>

          <div style={{marginBottom: 16}}>
            <strong><p>Product description</p></strong>
            <p>{this.state.productNotes}</p>
          </div>
          
          <strong><p>Product tags</p></strong>
          <p>{this.state.tags}</p>
        </div>

      </form>

      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, undefined)(ProductForm);