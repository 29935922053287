import HeroBannerBg from './HeroBannerBg'
import '../../styles/App.css';
import React, { Component } from "react";
// import { Button } from "react-bootstrap";
import logo from "./../../img/logo_white.svg";
import { Link } from "react-router-dom";
import { Grid, Col, Button } from "react-bootstrap";

class HeroBanner extends Component {
  render() {
    const scrollWhatWeDo = () => {
      const section = document.querySelector('#what-we-do');
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    return (
      <div>

        <div style={{ width: "100%", height: "100vh", zIndex: -1 }}>
          <HeroBannerBg />
          <div className="banner-container" style={{ zIndex: 1 }}>
            <div style={{ position: 'relative' }}>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div>

              <div style={{ textAlign: 'center'}}>
                <img src={logo} className="app-logo-landing" alt="logo" />
              </div>
                  <h1 style={{ fontWeight: '900'}} className="text-gradient text-gradient-colors gradient-text-size">WOVEN WIRE MESH</h1>
                </div>
                <div style={{ marginTop: 20, height: 100, textAlign: 'center' }}>
                  <Link to="/woven-wire-mesh/two-inch-opn-to-one-and-half-opn"><Button className="btn-blank btn-blue">WOVEN WIRE MESH</Button></Link>
                  <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
                  <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_WOVEN_MESH.pdf" target="blank">
                    <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
                  </a>
                </div>
              </div>




            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HeroBanner;



