import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid } from 'react-bootstrap';
import { PasswordForgetForm } from '../passwordForget';
import PasswordChangeForm from '../passwordChange';
import withAuthorization from '../session/withAuthorization';
import Layout from '../layout/Layout';

const AccountPage = ({ authUser }) =>
  <div>
    <Layout>
      <Grid>
      <h3>Edit profile: {authUser.email}</h3>
      <PasswordForgetForm />
      <PasswordChangeForm />
      </Grid>
    </Layout>
  </div>

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps)
)(AccountPage);