import React from 'react';
import { Table } from 'react-bootstrap';

const EightyMeshToThreeTwentyFiveMesh = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 80 Mesh - 325 Mesh</h4>
      <Table striped condensed hover>

        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>80X80</th>
            <td>.0055</td>
            <td>.007</td>
            <td>31.4</td>
            <td>.169</td>
          </tr>

          <tr>
            <th>80X80</th>
            <td>.007</td>
            <td>.0055</td>
            <td>19.4</td>
            <td>.274</td>
          </tr>

          <tr>
            <th>100X90</th>
            <td>.0045</td>
            <td>.0055/.0066</td>
            <td>32.7</td>
            <td>.117</td>
          </tr>

          <tr>
            <th>100X100</th>
            <td>.004</td>
            <td>.006</td>
            <td>36.0</td>
            <td>.110</td>
          </tr>

          <tr>
            <th>100X100</th>
            <td>.0045</td>
            <td>.0055</td>
            <td>30.3</td>
            <td>.142</td>
          </tr>

          <tr>
            <th>120X120</th>
            <td>.0037</td>
            <td>.0046</td>
            <td>30.7</td>
            <td>.102</td>
          </tr>

          <tr>
            <th>120X120</th>
            <td>.004</td>
            <td>.0043</td>
            <td>26.8</td>
            <td>.132</td>
          </tr>

          <tr>
            <th>150X150</th>
            <td>.0026</td>
            <td>.0041</td>
            <td>37.4</td>
            <td>.071</td>
          </tr>

          <tr>
            <th>150X150</th>
            <td>.003</td>
            <td>.0037</td>
            <td>30.4</td>
            <td>.093</td>
          </tr>

          <tr>
            <th>180X180</th>
            <td>.0018</td>
            <td>.0038</td>
            <td>46.0</td>
            <td>.047</td>
          </tr>

          <tr>
            <th>180X180</th>
            <td>.0023</td>
            <td>.0033</td>
            <td>34.7</td>
            <td>.067</td>
          </tr>

          <tr>
            <th>200X190</th>
            <td>.0021</td>
            <td>.0029/.0032</td>
            <td>34.9</td>
            <td>.050</td>
          </tr>

          <tr>
            <th>200X200</th>
            <td>.0021</td>
            <td>.0029</td>
            <td>33.6</td>
            <td>.062</td>
          </tr>

          <tr>
            <th>200X200</th>
            <td>.0023</td>
            <td>.0027</td>
            <td>29.1</td>
            <td>.072</td>
          </tr>

          <tr>
            <th>230X230</th>
            <td>.0014</td>
            <td>.0029</td>
            <td>46.0</td>
            <td>.031</td>
          </tr>

          <tr>
            <th>250X250</th>
            <td>.0014</td>
            <td>.0029</td>
            <td>42.3</td>
            <td>.035</td>
          </tr>

          <tr>
            <th>250X250</th>
            <td>.0016</td>
            <td>.0024</td>
            <td>36.0</td>
            <td>.041</td>
          </tr>

          <tr>
            <th>270X270</th>
            <td>.0014</td>
            <td>.0023</td>
            <td>38.6</td>
            <td>.037</td>
          </tr>

          <tr>
            <th>270X270</th>
            <td>.0016</td>
            <td>.0021</td>
            <td>38.0</td>
            <td>.043</td>
          </tr>

          <tr>
            <th>280X280</th>
            <td>.0014</td>
            <td>.0022</td>
            <td>38.0</td>
            <td>.039</td>
          </tr>

          <tr>
            <th>300X300</th>
            <td>.0012</td>
            <td>.0021</td>
            <td>40.5</td>
            <td>.031</td>
          </tr>

          <tr>
            <th>325X325</th>
            <td>.0014</td>
            <td>.0017</td>
            <td>30.5</td>
            <td>.043</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default EightyMeshToThreeTwentyFiveMesh;
