import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { Grid, Row, Col, Button } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';

import ExpandedMetalChart from './ExpandedMetal/ExpandedMetalChart'

import image1 from './../../img/product-img/expanded_metal/ExpandedMetal2.gif';
import image2 from './../../img/product-img/expanded_metal/ExpandedMetal3.jpg';
import image3 from './../../img/product-img/expanded_metal/ExpandedMetal4.jpg';
import image4 from './../../img/product-img/expanded_metal/ExpandedMetal1.gif';
import image5 from './../../img/product-img/expanded_metal/ExpandedMetal5.jpg';
import image6 from './../../img/product-img/expanded_metal/ExpandedMetal6.jpg';

const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <Col md={4}>
        <img src={image1} alt="ExpandedMetal2" draggable={false} className="img-fix" />
        <img src={image2} alt="ExpandedMetal3" draggable={false} className="img-fix" />
        <img src={image3} alt="ExpandedMetal4" draggable={false} className="img-fix" />
        <img src={image4} alt="ExpandedMetal1" draggable={false} className="img-fix" />
        <img src={image5} alt="ExpandedMetal5" draggable={false} className="img-fix" />
        <img src={image6} alt="ExpandedMetal6" draggable={false} className="img-fix" />
      </Col>
      <Col md={8}>
        <h3>Expanded Metal</h3>
        <p>PSM would appreciate the opportunity to handle any of your expanded metal needs. 
          We offer one of the widest ranges of expanded metal from stock or handle any of your custom fabricated needs. 
          Stocked sizes are typically 48" x 96", non standard sheets sizes are available as well. 
          We stock steel, 304/316 stainless, and aluminum. Please contact our sales staff today to help meet your requirements!
        </p>
        <h3>Measuring and Ordering Material</h3>
        <p>1. Specify opening, gauge of material, pattern,(standard or flattened), and desired alloy.</p>
        <p>2. Specify overall dimensions of material, and quantity required.</p>
        <p>Flattened expanded metal carbon steel</p>
          <ExpandedMetalChart />
      </Col>
    </div>
      )
    }
    
    
const ExpandedMetal = () => {
  return (
    <div>
        <Layout>
          <Grid fluid>
            <div className="expanded-metal-header">
              <Grid>
                <h2>Expanded Metal</h2>
                <h4>We can also supply expanded metal in exotic alloys upon request.</h4>
                <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
                <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_EXPANDED_METAL.pdf" target="blank">
                  <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
                </a>
              </Grid>
            </div>
          </Grid>
          <Grid>
            <Row className="show-grid">
              <MeasuringAndOrderingInstructions />
            </Row>
          </Grid>
        </Layout>
      </div>
      )
    }
    
export default ExpandedMetal;