import React from 'react';
import { Table } from 'react-bootstrap';

const EightMeshToSixTeenMesh = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 8 Mesh - 16 Mesh</h4>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>8X8</th>
            <td>.047</td>
            <td>.078</td>
            <td>38.9</td>
            <td>1.209</td>
          </tr>

          <tr>
            <th>8X8</th>
            <td>.054</td>
            <td>.071</td>
            <td>32.3</td>
            <td>1.627</td>
          </tr>

          <tr>
            <th>8X8</th>
            <td>.063</td>
            <td>.062</td>
            <td>24.6</td>
            <td>2.163</td>
          </tr>

          <tr>
            <th>9X9</th>
            <td>.023</td>
            <td>.088</td>
            <td>62.7</td>
            <td>.311</td>
          </tr>

          <tr>
            <th>9X9</th>
            <td>.032</td>
            <td>.079</td>
            <td>50.6</td>
            <td>.614</td>
          </tr>

          <tr>
            <th>9X9</th>
            <td>.035</td>
            <td>.076</td>
            <td>46.8</td>
            <td>.740</td>
          </tr>

          <tr>
            <th>9X9</th>
            <td>.047</td>
            <td>.064</td>
            <td>33.2</td>
            <td>1.392</td>
          </tr>

          <tr>
            <th>9X9</th>
            <td>.063</td>
            <td>.048</td>
            <td>17.3</td>
            <td>2.586</td>
          </tr>

          <tr>
            <th>9X9.5</th>
            <td>.063</td>
            <td>.048/.042</td>
            <td>17.3</td>
            <td>2.586</td>
          </tr>

          <tr>
            <th>10X10</th>
            <td>.020</td>
            <td>.080</td>
            <td>64.0</td>
            <td>.261</td>
          </tr>

          <tr>
            <th>10X10</th>
            <td>.023</td>
            <td>.077</td>
            <td>59.3</td>
            <td>.347</td>
          </tr>

          <tr>
            <th>10X10</th>
            <td>.025</td>
            <td>.075</td>
            <td>56.3</td>
            <td>.412</td>
          </tr>

          <tr>
            <th>10X10</th>
            <td>.028</td>
            <td>.072</td>
            <td>51.8</td>
            <td>.521</td>
          </tr>

          <tr>
            <th>10X10</th>
            <td>.032</td>
            <td>.068</td>
            <td>46.2</td>
            <td>.688</td>
          </tr>

          <tr>
            <th>10X10</th>
            <td>.035</td>
            <td>.065</td>
            <td>42.3</td>
            <td>.831</td>
          </tr>

          <tr>
            <th>10X10</th>
            <td>.041</td>
            <td>.059</td>
            <td>34.8</td>
            <td>1.163</td>
          </tr>

          <tr>
            <th>10X10</th>
            <td>.047</td>
            <td>.053</td>
            <td>28.1</td>
            <td>1.484</td>
          </tr>

          <tr>
            <th>12X12</th>
            <td>.018</td>
            <td>.065</td>
            <td>60.8</td>
            <td>.255</td>
          </tr>

          <tr>
            <th>12X12</th>
            <td>.023</td>
            <td>.060</td>
            <td>51.8</td>
            <td>.422</td>
          </tr>

          <tr>
            <th>12X12</th>
            <td>.028</td>
            <td>.055</td>
            <td>43.6</td>
            <td>.635</td>
          </tr>

          <tr>
            <th>12X12</th>
            <td>.032</td>
            <td>.051</td>
            <td>37.5</td>
            <td>.843</td>
          </tr>

          <tr>
            <th>12X12</th>
            <td>.035</td>
            <td>.048</td>
            <td>33.2</td>
            <td>1.021</td>
          </tr>

          <tr>
            <th>12X12</th>
            <td>.041</td>
            <td>.042</td>
            <td>25.4</td>
            <td>1.367</td>
          </tr>

          <tr>
            <th>14X14</th>
            <td>.017</td>
            <td>.054</td>
            <td>57.2</td>
            <td>.266</td>
          </tr>

          <tr>
            <th>14X14</th>
            <td>.018</td>
            <td>.053</td>
            <td>55.1</td>
            <td>.299</td>
          </tr>


          <tr>
            <th>14X14</th>
            <td>.020</td>
            <td>.051</td>
            <td>51.0</td>
            <td>.372</td>
          </tr>

          <tr>
            <th>14X14</th>
            <td>.023</td>
            <td>.048</td>
            <td>45.2</td>
            <td>.498</td>
          </tr>

          <tr>
            <th>14X14</th>
            <td>.025</td>
            <td>.046</td>
            <td>41.5</td>
            <td>.593</td>
          </tr>

          <tr>
            <th>14X14</th>
            <td>.028</td>
            <td>.043</td>
            <td>36.2</td>
            <td>.755</td>
          </tr>

          <tr>
            <th>14X14</th>
            <td>.032</td>
            <td>.039</td>
            <td>29.8</td>
            <td>1.055</td>
          </tr>

          <tr>
            <th>16X16</th>
            <td>.016</td>
            <td>.0465</td>
            <td>55.4</td>
            <td>.271</td>
          </tr>

          <tr>
            <th>16X16</th>
            <td>.018</td>
            <td>.0445</td>
            <td>50.7</td>
            <td>.345</td>
          </tr>

          <tr>
            <th>16X16</th>
            <td>.023</td>
            <td>.0395</td>
            <td>39.9</td>
            <td>.577</td>
          </tr>

          <tr>
            <th>16X16</th>
            <td>.025</td>
            <td>.0375</td>
            <td>36.0</td>
            <td>.689</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default EightMeshToSixTeenMesh;
