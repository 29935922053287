import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import firebase from "firebase";
import Layout from '../../layout/Layout'
import { addProduct } from '../../../services/cart/actions';
import PropTypes from 'prop-types';
import FloatCart from '../../FloatCart'

class ProductDetails extends Component {
  constructor(props){
    super(props)
    this.state = {
      profileData: {},
      city_name: '',
      weight_price: null
    }
  }

  componentDidMount() {
    const { id }= this.props.match.params
    this.mounted = true;

      if (this.mounted) {
        firebase.database().ref(`products/${id}/`).once('value', snap => {
          if (snap.val()) {
            this.setState({ isEmpty: false });
            this.setState({
              profileData: snap.val()
            });
          } else {
            this.setState({ isEmpty: true });
          }
          this.setState({ isLoading: false });
        })
      }
  }

  render(){

    let objectState = this.state.profileData
    let {  manufacturer, img_url, productAlloy, productDims, productOpn, productWire, productName, productCategories, description, type, productNotes, sku, price, quantity } = _.toPlainObject(objectState) 

    let productObj = {
      ...objectState,
      productName, 
      price, 
      quantity, 
    }
    return(
      <Layout>
        <div className="container" style={{marginTop: 90, marginBottom: 90}}>
          <div className="app-wrapper">
    
          <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="card-img-container" style={{borderRadius: 32, overflow: 'hidden'}}>
                <img src={img_url} alt="product" className="card-img-container" />
              </div>
          </div>


          <div className="col-md-6 col-sm-12 col-xs-12">

              <div className="list-card" style={{flexDirection: 'row'}}>
                <div style={{width: '100%'}}>
                  <div style={{padding: 4}}>
                    
                    <div style={{marginTop: 8}}>
                      <p style={{fontSize: 32, fontWeight: 'bold'}}>{_.toUpper(productCategories)}</p>
                    </div>
                    
                    <div style={{marginTop: 8}}>
                      <p style={{fontSize: 24, fontWeight: 'bold'}}>{_.toUpper(productName)}</p>
                    </div>

                    <p style={{fontSize: 30, fontWeight: '900'}}>{description}</p>
                    
                    <div style={{marginTop: 10}}>
                        <p>{productNotes}</p>                  
                    </div>

                    {/* <div style={{marginTop: 10}}>
                      <strong><p style={{fontSize: 12}}>MAKER</p></strong>
                      <div className="badge badge-secondary" style={{marginTop: 8}}>
                        <strong><p>{manufacturer}</p></strong>
                      </div>
                    </div> */}

              <div style={{display: 'flex', flexDirection: 'row',}}>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {productAlloy ? (
                      <>
                        <p style={{fontWeight: 'bold'}}>Alloy</p><strong style={{color: '#999999', fontWeight: 900}}>{productAlloy}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {productOpn ? (
                      <>
                        <p style={{fontWeight: 'bold'}}>OPN</p><strong style={{color: '#999999', fontWeight: 900}}>{productOpn}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {productWire ? (
                      <>
                        <p style={{fontWeight: 'bold'}}>Wire</p><strong style={{color: '#999999', fontWeight: 900}}>{productWire}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {productDims ? (
                      <>
                        <p style={{fontWeight: 'bold'}}>Dims</p><strong style={{color: '#999999', fontWeight: 900}}>{productDims}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
              </div>

                    <div style={{marginTop: 10}}>

                    <div style={{marginTop: 10}}>
                      <strong><p style={{fontSize: 12}}>PRICE</p></strong>
                    </div>

                    {/* <div style={{marginTop: 10}}>
                      <div style={{marginTop: 10}}>
                        <button className="btn btn-disable btn-lg"><i className="fas fa-dollar-sign nav-bar-icon"></i> {price}</button>
                      </div>
                    </div> */}

                      <div>
                        <div className="input-group input-group dropdown-menu-right" style={{marginTop: 10}}>
                          <div className="select-container"></div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <div style={{marginTop: 10, marginRight: 8}}>
                            <button className="btn btn-disable btn-lg"><i className="fas fa-dollar-sign nav-bar-icon"></i> {price}</button>
                          </div>
                          {this.props.authUser ? (
                            <div style={{marginTop: 8}}>
                              <button className="btn btn-primary btn-lg" style={{backgroundColor: '#212121'}} onClick={() => this.props.addProduct(productObj)} data-sku={sku}>Add to cart</button>
                            </div>
                          ) : (
                            <div/>
                          )}

                        </div>
                      </div>
                    </div>


                    {/* {  
                        this.props.user.uid === uid ? (
                          <div style={{marginTop: 10}}>
                            <Link to={`/edit/${id}`}>
                              <button className="btn btn-disable btn-lg"><i className="fas fa-edit nav-bar-icon"></i> edit</button>
                            </Link>
                          </div>
                          ) : (
                          <div />
                        )
                      } */}

                  </div>


                </div>
              </div>
            </div>
          
        </div>
        
        {this.props.authUser ? (
          <FloatCart dispensaryProps={objectState}/>
          ) : (
            <div/>
            )}


          </div>

      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
  products: state.shelf.products,
});

ProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {addProduct})(ProductDetails);


