import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const aggregateImg = 'https://s3.us-east-2.amazonaws.com/psm-webapp/assets/fulltruck2.jpg';

const Industrial = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={4}>
              <h1>Industrial</h1>
              <h3>We serve and supply most industrial applications</h3>
              <p>
              We manufacture screens to serve the food and beverage industry, 
              heat treating industry, water screens and debris screens for industrial plants, 
              pulp and paper plants, aerospace, sand and gravel, etc… We supply wire mesh, 
              perforated plate, tubular slit mesh, and expanded metal for the filtration industry and 
              industrial needs. PSM offers complete service for the air exchanger industry such as fan guarding, 
              hail guards, as well was bug screens.
            </p>
            </Col>
            <Col md={8}>
              <img src={aggregateImg} alt="Industrial" className="responsive-img" />
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Industrial;