import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const aggregateImg = 'https://s3.us-east-2.amazonaws.com/psm-webapp/assets/fulltruck2.jpg';

const RefiningPetroChem = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={4}>
              <h1>Refining Petro/Chem</h1>
              <h3>We can handle Reactor Internals</h3>
              <p>
                Such as Reactor Screens, Catalyst Support Beds, Profile V-Wire/Wedge wire screens, 
                Support Grids, Hold Down Grids, Scallops, Baskets, Strainers, Perforated Plate, 
                Expanded Metal, Tie Wire, etc. We can supply reactor internals for Sulphur Reactors, 
                Alki Units, Claus Reactors, Chloride Treaters, Dehydrators, and other types of Vessel and 
                Reactor Internals. We can also supply any exterior grating such as stair treads, catwalks, 
                platforms, ladders, ladder rungs, etc in most all alloys such as 304SS, 316SS, Carbon Steel, 
                Galvanized, Aluminum, and Fiberglass. Please contact one of our experienced sales staff to help 
                find a solution to your requirement.</p>
                <p>We are available 24/7 for emergency turnaround requirements</p>
                <p>Proud approved vendors for Exxon, Chevron, Conoco Phillips, Shell, Valero, Motiva, Lion Oil, Murphy Oil, BASF, and DCP Midstream.</p>
            </Col>
            <Col md={8}>
              <img src={aggregateImg} alt="Refining Petro Chem"  className="responsive-img" />
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default RefiningPetroChem;