import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const aggregateImg = 'https://s3.us-east-2.amazonaws.com/psm-webapp/assets/fulltruck2.jpg';

const Aggregate = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={4}>
              <h1>Aggregate</h1>
              <h3>Capabilities</h3>
              <p>
                In addition to woven panels we also have the capabilities to supply aggregate
                replacement screens and perforated plate of all grades of carbon, oil tempered,
                stainless steel, galvanized wire, aluminum, and other products used for separating and screening.
                We can provide most hook types for standard and custom screen decks.
                We would appreciate the opportunity to quote any screening, or sizing requirements you might have.
            </p>
              <h3>Measuring and Ordering Material</h3>
              <p>1. Specify opening or mesh count, wire diameter and desired alloy.</p>
              <p>2. Specify overall dimensions of material, Outside Clamping Width or Inside Clamping Width, overlap if required, hook type, hooks up or reverse crimp(1 hook up, 1 hook down), and quantity required.</p>
              <p>3. If ordering a slotted opening, the direction of the slot should be specified.</p>
            </Col>
            <Col md={8}>
              <img src={aggregateImg} alt="Aggregate"  className="responsive-img" />
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Aggregate;