import React from 'react';
import { Table } from 'react-bootstrap';

const ThreeSixteenthToOneEight = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 3/16&#8243; OPN - 1/8&#8243; OPN</h4>
      <Table striped condensed hover>

        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.047</td>
            <td>.187</td>
            <td>63.9</td>
            <td>.62</td>

          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.054</td>
            <td>.187</td>
            <td>60.3</td>
            <td>.79</td>

          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.063</td>
            <td>.187</td>
            <td>56</td>
            <td>1.05</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.072</td>
            <td>.187</td>
            <td>52.2</td>
            <td>1.33</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.08</td>
            <td>.187</td>
            <td>49.1</td>
            <td>1.6</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.092</td>
            <td>.187</td>
            <td>45.1</td>
            <td>2.04</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.105</td>
            <td>.187</td>
            <td>41.1</td>
            <td>2.56</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.12</td>
            <td>.187</td>
            <td>37.2</td>
            <td>3.22</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.135</td>
            <td>.187</td>
            <td>33.8</td>
            <td>3.92</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.148</td>
            <td>.187</td>
            <td>31.3</td>
            <td>4.57</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.162</td>
            <td>.187</td>
            <td>28.8</td>
            <td>5.3</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.177</td>
            <td>.187</td>
            <td>26.5</td>
            <td>6.12</td>
          </tr>

          <tr>
            <th>3/16&#8243; OPN</th>
            <td>.192</td>
            <td>.187</td>
            <td>24.4</td>
            <td>6.97</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.032</td>
            <td>.12</td>
            <td>63.4</td>
            <td>.43</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.035</td>
            <td>.12</td>
            <td>61</td>
            <td>.5</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.041</td>
            <td>.12</td>
            <td>56.7</td>
            <td>.67</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.047</td>
            <td>.12</td>
            <td>52.8</td>
            <td>.85</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.054</td>
            <td>.12</td>
            <td>48.8</td>
            <td>1.09</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.063</td>
            <td>.12</td>
            <td>44.2</td>
            <td>1.43</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.072</td>
            <td>.12</td>
            <td>40.3</td>
            <td>1.79</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.08</td>
            <td>.12</td>
            <td>37.2</td>
            <td>2.15</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.092</td>
            <td>.12</td>
            <td>33.2</td>
            <td>2.71</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.105</td>
            <td>.12</td>
            <td>29.5</td>
            <td>3.37</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.12</td>
            <td>.12</td>
            <td>26</td>
            <td>4.19</td>
          </tr>

          <tr>
            <th>1/8&#8243; OPN</th>
            <td>.135</td>
            <td>.12</td>
            <td>23.1</td>
            <td>4.98</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default ThreeSixteenthToOneEight;