import React from 'react';
import { Table } from 'react-bootstrap';

const TwoMeshToFourMesh = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 2 Mesh - 4 Mesh</h4>
      <Table striped condensed hover>

          <thead>
            <tr>
              <th><h5>MESH</h5></th>
              <th><h5>WIRE DIA.</h5></th>
              <th><h5>OPENING</h5></th>
              <th><h5>OPEN AREA %</h5></th>
              <th><h5>WEIGHT/SQFT</h5></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th>2X2</th>
              <td>.105</td>
              <td>.395</td>
              <td>62.2</td>
              <td>1.442</td>

            </tr>

            <tr>
              <th>2X2</th>
              <td>.120</td>
              <td>.380</td>
              <td>57.8</td>
              <td>1.896</td>

            </tr>

            <tr>
              <th>2X2</th>
              <td>.135</td>
              <td>.365</td>
              <td>53.3</td>
              <td>2.417</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.047</td>
              <td>.286</td>
              <td>73.6</td>
              <td>.428</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.054</td>
              <td>.279</td>
              <td>70.1</td>
              <td>.567</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.063</td>
              <td>.270</td>
              <td>65.6</td>
              <td>.776</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.072</td>
              <td>.261</td>
              <td>61.3</td>
              <td>1.019</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.080</td>
              <td>.253</td>
              <td>57.6</td>
              <td>1.264</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.092</td>
              <td>.241</td>
              <td>52.3</td>
              <td>1.687</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.105</td>
              <td>.228</td>
              <td>76.8</td>
              <td>2.22</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.120</td>
              <td>.213</td>
              <td>40.8</td>
              <td>2.939</td>
            </tr>

            <tr>
              <th>3X3</th>
              <td>.135</td>
              <td>.198</td>
              <td>35.3</td>
              <td>3.776</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.028</td>
              <td>.222</td>
              <td>78.9</td>
              <td>.202</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.032</td>
              <td>.218</td>
              <td>76.0</td>
              <td>.264</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.035</td>
              <td>.215</td>
              <td>74.0</td>
              <td>.317</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.041</td>
              <td>.209</td>
              <td>69.9</td>
              <td>.436</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.047</td>
              <td>.203</td>
              <td>65.9</td>
              <td>.576</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.054</td>
              <td>.196</td>
              <td>61.5</td>
              <td>.764</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.063</td>
              <td>.187</td>
              <td>56.0</td>
              <td>1.048</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.072</td>
              <td>.178</td>
              <td>50.7</td>
              <td>1.382</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.080</td>
              <td>.170</td>
              <td>46.2</td>
              <td>1.721</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.092</td>
              <td>.158</td>
              <td>39.9</td>
              <td>2.310</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.105</td>
              <td>.145</td>
              <td>33.6</td>
              <td>3.062</td>
            </tr>

            <tr>
              <th>4X4</th>
              <td>.120</td>
              <td>.130</td>
              <td>27.0</td>
              <td>3.886</td>
            </tr>
          </tbody>
      </Table>
    </div>
  )
}

export default TwoMeshToFourMesh;
