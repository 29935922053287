import React from 'react';
import { Table } from 'react-bootstrap';

const SixTeenMeshToThirtyMesh = () => {
  return (
    <div>
     <h4>Woven Wire Mesh 16 Mesh - 30 Mesh</h4>
      <Table striped condensed hover>

          <thead>
            <tr>
              <th><h5>MESH</h5></th>
              <th><h5>WIRE DIA.</h5></th>
              <th><h5>OPENING</h5></th>
              <th><h5>OPEN AREA %</h5></th>
              <th><h5>WEIGHT/SQFT</h5></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th>16X16</th>
              <td>.028</td>
              <td>.0345</td>
              <td>30.5</td>
              <td>.836</td>

            </tr>

            <tr>
              <th>16X16</th>
              <td>.032</td>
              <td>.0305</td>
              <td>23.8</td>
              <td>1.119</td>

            </tr>
            <th>16X16</th>
            <td>.035</td>
            <td>.0275</td>
            <td>19.4</td>
            <td>1.366</td>
            <tr>

            </tr>

            <tr>
              <th>18X14</th>
              <td>.011</td>
              <td>.045/.060</td>
              <td>70.6</td>
              <td>.102</td>
            </tr>

            <tr>
              <th>18X18</th>
              <td>.009</td>
              <td>.0466</td>
              <td>70.4</td>
              <td>0.95</td>
            </tr>

            <tr>
              <th>18X18</th>
              <td>.017</td>
              <td>.0386</td>
              <td>48.3</td>
              <td>.348</td>
            </tr>

            <tr>
              <th>18X18</th>
              <td>.020</td>
              <td>.0356</td>
              <td>41.1</td>
              <td>.490</td>
            </tr>

            <tr>
              <th>18X18</th>
              <td>.023</td>
              <td>.0326</td>
              <td>34.4</td>
              <td>.660</td>
            </tr>

            <tr>
              <th>18X18</th>
              <td>.025</td>
              <td>.0306</td>
              <td>30.3</td>
              <td>.750</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.009</td>
              <td>.041</td>
              <td>67.2</td>
              <td>1.050</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.014</td>
              <td>.036</td>
              <td>51.8</td>
              <td>.261</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.015</td>
              <td>.035</td>
              <td>49.0</td>
              <td>.301</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.016</td>
              <td>.034</td>
              <td>46.2</td>
              <td>.344</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.017</td>
              <td>.033</td>
              <td>43.6</td>
              <td>.391</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.018</td>
              <td>.032</td>
              <td>41.0</td>
              <td>.441</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.020</td>
              <td>.030</td>
              <td>36.0</td>
              <td>.552</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.023</td>
              <td>.027</td>
              <td>29.2</td>
              <td>.708</td>
            </tr>

            <tr>
              <th>20X20</th>
              <td>.025</td>
              <td>.025</td>
              <td>25.0</td>
              <td>.850</td>
            </tr>

            <tr>
              <th>24X24</th>
              <td>.0075</td>
              <td>.0342</td>
              <td>67.4</td>
              <td>.088</td>
            </tr>

            <tr>
              <th>24X24</th>
              <td>.010</td>
              <td>.0317</td>
              <td>57.9</td>
              <td>.158</td>
            </tr>

            <tr>
              <th>24X24</th>
              <td>.014</td>
              <td>.0277</td>
              <td>44.2</td>
              <td>.318</td>
            </tr>

            <tr>
              <th>24X24</th>
              <td>.020</td>
              <td>.0217</td>
              <td>27.1</td>
              <td>.648</td>
            </tr>

            <tr>
              <th>26X26</th>
              <td>.015</td>
              <td>.0235</td>
              <td>37.3</td>
              <td>.402</td>
            </tr>

            <tr>
              <th>26X26</th>
              <td>.016</td>
              <td>.0225</td>
              <td>34.2</td>
              <td>.461</td>
            </tr>

            <tr>
              <th>26X26</th>
              <td>.017</td>
              <td>.0215</td>
              <td>31.2</td>
              <td>.526</td>
            </tr>


            <tr>
              <th>26X26</th>
              <td>.018</td>
              <td>.0205</td>
              <td>28.4</td>
              <td>.566</td>
            </tr>

            <tr>
              <th>26X26</th>
              <td>.020</td>
              <td>.0185</td>
              <td>23.1</td>
              <td>.713</td>
            </tr>

            <tr>
              <th>28X28</th>
              <td>.010</td>
              <td>.0257</td>
              <td>51.8</td>
              <td>.186</td>
            </tr>

            <tr>
              <th>28X28</th>
              <td>.0135</td>
              <td>.0222</td>
              <td>38.6</td>
              <td>.349</td>
            </tr>

            <tr>
              <th>30X30</th>
              <td>.0085</td>
              <td>.0248</td>
              <td>55.4</td>
              <td>.143</td>
            </tr>

            <tr>
              <th>30X30</th>
              <td>.0095</td>
              <td>.0238</td>
              <td>51.0</td>
              <td>.180</td>
            </tr>

            <tr>
              <th>30X30</th>
              <td>.010</td>
              <td>.0233</td>
              <td>48.9</td>
              <td>.200</td>
            </tr>

            <tr>
              <th>30X30</th>
              <td>.011</td>
              <td>.0233</td>
              <td>44.8</td>
              <td>.245</td>
            </tr>

          </tbody>
      </Table>
    </div>
  )
}

export default SixTeenMeshToThirtyMesh;
