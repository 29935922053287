import React from 'react';
import { Table } from 'react-bootstrap';

const OneAndHalfToOne = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 1 1/2” OPN - 1” OPN</h4>
      <Table striped condensed hover>

        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1 1/2"OPN</th>
            <td>.162</td>
            <td></td>
            <td></td>
            <td>1.20</td>
          </tr>

          <tr>
            <th>1 1/2"OPN</th>
            <td>.192</td>
            <td></td>
            <td></td>
            <td>1.65</td>
          </tr>

          <tr>
            <th>1 1/2"OPN</th>
            <td>.250</td>
            <td></td>
            <td></td>
            <td>2.70</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.080</td>
            <td></td>
            <td></td>
            <td>.38</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.092</td>
            <td></td>
            <td></td>
            <td>.50</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.105</td>
            <td></td>
            <td></td>
            <td>.64</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.120</td>
            <td></td>
            <td></td>
            <td>.83</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.135</td>
            <td></td>
            <td></td>
            <td>1.04</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.148</td>
            <td></td>
            <td></td>
            <td>1.23</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.162</td>
            <td></td>
            <td></td>
            <td>1.46</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.177</td>
            <td></td>
            <td></td>
            <td>1.72</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.192</td>
            <td></td>
            <td></td>
            <td>2.01</td>
          </tr>

          <tr>
            <th>1" OPN</th>
            <td>.250</td>
            <td></td>
            <td></td>
            <td>3.26</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default OneAndHalfToOne;