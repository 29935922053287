import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const ShippingAndCrating = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={12}>
              <h1>Shipping and Crating</h1>
              <p>PSM takes pride in custom packing all orders before they leave our facilities. 
                  We custom manufacture our skids/crates for every order. 
                  If you have any special request for air freight, crating, expedited freight, labels, MTR’s, 
                  Pc Marks, etc… we are here to accommodate. All our packing products are approved for air 
                  and export shipping. Our wood is fumigated and bug stamped.</p>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default ShippingAndCrating;