import React from "react";
import Layout from "../layout/Layout";
import CallToActionHomePage from "./CallToActionHomePage";
import ProductsComponent from "./ProductsComponent";
import MissionStatements from "./MissionStatements";
import Locations from "./Locations";
import SalesCallToAction from "./SalesCallToAction";
import SocialComponent from "./SocialComponent";
import AggregateCallToAction from "./AggregateCallToAction"
import WovenMeshCallToActionHero from "./WovenMeshCallToActionHero"
import {Helmet} from "react-helmet";
// Style sheet
import "../../styles/App.css";
import HeroHeaderLanding from "./HeroHeaderLanding";
import Footer from "../layout/Footer";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Woven Wire Mesh | Welded Wire Mesh | Perf Metal and More | Presicion Screen Manufacturing</title>
    <link rel="canonical" href="http://psm-mfg.com" />
  </Helmet>
)

const LandingPage = () => 
  <div>
    <div>
      <HeaderTags/>
      {/* <WovenMeshCallToActionHero /> */}
      <HeroHeaderLanding/>
      {/* <CallToActionHomePage /> */}
      <ProductsComponent />
      <AggregateCallToAction />
      <MissionStatements />
      <Locations />
      <SalesCallToAction />
      <SocialComponent />
      <Footer/>
    </div>
  </div>


export default LandingPage;
