import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import Layout       from '../layout/Layout';
import ContactForm  from './ContactForm';
import instagram    from '../../img/instagram@2x.png';
import facebook     from '../../img/facebook@2x.png';
import twitter      from '../../img/twitter@2x.png';
import linkedin     from '../../img/linkedin@2x.png';

const Contact = () => {
  return (
    <div>
      <Layout>
        <Grid>            
            <Col md={6} style={{marginTop: 100}}>
              <h1 style={{textTransform: 'uppercase', fontSize: 48, fontWeight: '900'}}>Register for our educational webinar</h1>
              <h3 style={{fontSize: 24, fontWeight: '500', lineHeight: 1.6}}>Whether you are new to this industry or an expert, sign up here to learn more about our capabilities and have all your questions answered.</h3>
            </Col>
            <Col md={6}>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeM4g99FaJ1HvAHZH5yI5xHBBF_Z9fzG9b8w34_zO5pch7tJA/viewform?embedded=true" width="640" height="833" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </Col>
        </Grid>
      </Layout>
    </div>
  )
}

export default Contact;