import React from "react";
import { Link } from "react-router-dom";

import woven_wire_mesh from "./../../img/woven_wire@2x.png";
import welded_wire_mesh from "./../../img/welded_wire@2x.png";
import perforated_metal from "./../../img/perforated_metal@2x.png";
import tie_wire from "./../../img/tie_wire@2x.png";
import hardware_cloth from "./../../img/hardware_cloth@2x.png";
import fiberglass_grating from "./../../img/fiberglass_grating@2x.png";
import safety_grating from "./../../img/safety_grating@2x.png";
import expanded_metal from "./../../img/expanded_metal@2x.png";
import bar_grating from "./../../img/bar_grating@2x.png";

import { Grid, Row, Col } from "react-bootstrap";

const ProductsComponent = () => {
  return (
    <div className="products-homepage-container">
      <Grid>
        <Row>
          <Col md={12}>
            <div style={{height: 50, marginBottom: 16}}>
              <center>
                <p className="section-heading">Our experienced staff has over 50 years experience in the industry.</p>
              </center>
            </div>
            <div className="industries-call-out">
              <Col md={3} className="cat-call-out mb-30">
                <Link to="/industrial">Industrial</Link>
              </Col>
              <Col md={3} className="cat-call-out mb-30">
                <Link to="/aggregate">Aggregate</Link>
              </Col>
              <Col md={3} className="cat-call-out mb-30">
                <Link to="/architectural">Architectural</Link>
              </Col>
              <Col md={3} className="cat-call-out mb-30">
                <Link to="/refining-petro-chem">Refining/Petro-Chem</Link>
              </Col>
            </div>
          </Col>

          <Col xs={12} sm={4} md={4}>
            <Link to="/woven-wire-mesh/two-inch-opn-to-one-and-half-opn">
              <img
                src={woven_wire_mesh}
                className="img-responsive"
                draggable="false"
                alt="Woven Wire Mesh"
              />
            </Link>
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Link to="/welded-wire-mesh">
              <img
                src={welded_wire_mesh}
                className="img-responsive"
                draggable="false"
                alt="Welded Wire Mesh"
              />
            </Link>
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Link to="/perforated-metal">
              <img
                src={perforated_metal}
                className="img-responsive"
                draggable="false"
                alt="Perforated Metal"
              />
            </Link>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col xs={12} sm={4} md={4}>
            <Link to="/tie-wire">
              <img
                src={tie_wire}
                className="img-responsive"
                draggable="false"
                alt="Tie Wire"
              />
            </Link>
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Link to="/hardware-cloth">
              <img
                src={hardware_cloth}
                className="img-responsive"
                draggable="false"
                alt="Hardware Cloth"
              />
            </Link>
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Link to="/fiberglass-grating">
              <img
                src={fiberglass_grating}
                className="img-responsive"
                draggable="false"
                alt="Fiberglass Grating"
              />
            </Link>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col xs={12} sm={4} md={4}>
            <Link to="/safety-grating">
              <img
                src={safety_grating}
                className="img-responsive"
                draggable="false"
                alt="Safety Grating"
              />
            </Link>
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Link to="/expanded-metal">
              <img
                src={expanded_metal}
                className="img-responsive"
                draggable="false"
                alt="Expanded Metal"
              />
            </Link>
          </Col>
          <Col xs={12} sm={4} md={4}>
            <Link to="/bar-grating">
              <img
                src={bar_grating}
                className="img-responsive"
                draggable="false"
                alt="Bar Grating"
              />
            </Link>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default ProductsComponent;
