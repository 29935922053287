import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import Layout       from '../layout/Layout';
import ContactForm  from './ContactForm';
import instagram    from '../../img/instagram@2x.png';
import facebook     from '../../img/facebook@2x.png';
import twitter      from '../../img/twitter@2x.png';
import linkedin     from '../../img/linkedin@2x.png';

const Contact = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row>
            <Col md={6} >
            <Row>
            <h3>Locations</h3>
            <Col md={6}>
              <div>
              <p>Oklahoma </p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3209.7908324000164!2d-95.70127388427981!3d36.43844018002463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b6550f72053d49%3A0xbf5d04c931330735!2sPrecision+Screen+Manufacturing!5e0!3m2!1sen!2sus!4v1526922216066"
                className="map-styles"
                width="100%"
                height="450"
                allowFullScreen
                title="Oologah Location"
              >
              </iframe>
                <b><p> PSM Oologah </p></b>
                <p> 7287 E HWY 88</p>
                <p> Oologah</p>
                <p> Oklahoma</p>
                <p> 74053</p>
                </div>
                <p>Follow us</p>
              <hr />
              <Col xs={3} md={3}><a href="https://www.instagram.com/psm_mfg/"><img src={instagram} alt="instagram" className="img-responsive" /></a></Col>
              <Col xs={3} md={3}><a href="https://www.facebook.com/PrecisionScreenManufacturing"><img src={facebook} alt="facebook" className="img-responsive" /></a></Col>
              <Col xs={3} md={3}><a href="https://twitter.com/psm_mfg"><img src={twitter} alt="twitter" className="img-responsive" /></a></Col>
              <Col xs={3} md={3}><a href="https://www.linkedin.com/company/precision-screen-manufacturing/"><img src={linkedin} alt="linkedin" className="img-responsive" /></a></Col>
              </Col>
              <Col md={6}>
              <p> Texas </p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13879.809082846808!2d-98.057044!3d29.575992000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c5f3c1f4fa817%3A0x143cf4d0f2bfffe3!2sPrecision%20Screen%20Manufacturing!5e0!3m2!1sen!2sus!4v1600803234826!5m2!1sen!2sus" 
                className="map-styles"
                width="100%"
                height="450"
                allowFullScreen
                title="Texas Location"
              >
               </iframe>
              <b><p> PSM San Antonio</p></b>
              <p> 1487 Schumann RD</p>
              <p> Seguin</p>
              <p> Texas</p>
              <p> 78155</p>
              </Col>
              </Row>
            </Col>
            <Col md={6}>
              <h3>Send us a message</h3>
              <p>Call: (866) 596-7537</p>
                <iframe src="https://forms.monday.com/forms/embed/f54da6d19e1348f832dddb898ef2d131?r=use1" width="100%" height="700" style={{border: 0}}></iframe>
                {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeNpJUZKXaFO5u73UOJXx_qGZYd_TTSW0SaPHrC3p75lgFoBg/viewform?embedded=true" width="640" height="729" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Contact;