import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { Grid, Row, Col, Button, Label } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';

import image1 from './../../img/product-img/welded_wire_mesh/WeldedWireMesh.jpg';
// Instructionn Components
const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <Col md={4}>
        <img src={image1} alt="Welded Wire Mesh" draggable={false} className="img-fix" />
      </Col>
      <Col md={8}>
        <h2>Stocked Welded Mesh</h2>
        <p>We stock and offer standard sheet sizes of</p>
        <h4><Label>4′ x 8′</Label> <Label>4′ x 10′</Label> <Label>5′ x 8′</Label> <Label>5′ x 10′</Label></h4>
        <p>We offer mild steel, plain steel, Stainless, Galvanized, as well as PVC coated welded mesh.</p>
        <hr />
        <h4>Custom Welded Mesh</h4>
        <p>We can also supply all your custom welded needs also.</p>
        <p>When planning your customer wire requirements, please provide all of the following items:</p>
        <ol>
          <li>Size of Opening</li>
          <li>Wire Diameter</li>
          <li>Alloy</li>
          <li>Dimensions</li>
          <li>Quantity</li>
          <li>Edge Requirements</li>
        </ol>
      </Col>
    </div>
  )
}


const WeldedWireMesh = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="welded-wire-mesh-header">
            <Grid>
              <h2>Welded Wire Mesh</h2>
              <h4>Welded Wire cloth/screen manufacturer with weaving <br />facilities in San Antonio and Tulsa.</h4>
              <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_WELDED_WIRE_MESH.pdf" target="blank">
              <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
            <MeasuringAndOrderingInstructions />
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default WeldedWireMesh;