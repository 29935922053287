// Users Profile Reducer
import { FETCH_PROFILE_PROPS } from '../actions/profile'
const defaultUserState = [];

export default (state = defaultUserState, action) => {
  switch (action.type) {
    
    case FETCH_PROFILE_PROPS:
      return action.payload;

    default:
      return state;
  }
};