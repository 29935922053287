import React from 'react';

import { Grid, Row, Col, Button } from 'react-bootstrap';

import map from './../../img/map@2x.png';

const Locations = () => {
  return (
    <div  className="mt-30">
      <Grid>
        <Row>
            <Col xs={12} sm={12} md={12}>
            <h3 className="sub-heading">LOCATIONS</h3>
            <h1 className="section-heading">Strategically Located</h1>
            </Col>
          <Col xs={12} md={4}>
            <Col className="no-margin" xs={6} md={6}>
              <h3>Oklahoma</h3>
              <p>7287 E HWY 88<br/>Oologah<br/> Oklahoma 74053<br/>P: (866) 596-7537</p>
              <a href="https://www.google.com/maps/place/7287+OK-88,+Oologah,+OK+74053/@36.4386368,-95.7012938,17z/data=!3m1!4b1!4m5!3m4!1s0x87b6550f70a1771d:0xf46e60496148b81d!8m2!3d36.4378677!4d-95.7001863" target="blank">
              <Button className="btn-blank btn-blue">Directions</Button></a>
            </Col>
            <Col xs={6} md={6}>
              <h3>Texas</h3>
              <p>1487 Schumann RD<br/>Seguin<br/> Texas 78155 <br/> P: (866) 596-7537</p>
              <a href="https://www.google.com/search?safe=active&rlz=1C5CHFA_enUS839US840&sxsrf=ALeKk01PEGZq2KCF5EGebEO8FHR1hnOyqw:1601564914929&q=1487+Schumann+RD+Seguin+Tx+7815&npsic=0&rflfq=1&rlha=0&tbm=lcl&ved=2ahUKEwim8Nqe1pPsAhUFd6wKHT64BY8QtgN6BAgLEAc&rldoc=1" target="blank">
              <Button className="btn-blank btn-blue">Directions</Button></a>
            </Col>
          </Col>
          <Col xs={12} md={8}>
            <img src={map} className="img-responsive" draggable="false" alt="PSM Locations"/>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default Locations;