import React from 'react';

import { Grid, Row  } from 'react-bootstrap';
import Layout from '../layout/Layout';

const Industries = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <h1>Will show Industries</h1>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Industries;