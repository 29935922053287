import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const AboutUs = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={6}>
              <h1>Jobs</h1>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc5S6puRQWKhsHPoO8owb-iUk_xyo2x5SIaur6QIBhtFcztlg/viewform?embedded=true" width="100%" height="4074" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </Col>
            <Col md={6}>
              <h1>Download Job Application</h1>
              <a href="https://psm-webapp.s3.us-east-2.amazonaws.com/downloads/PSM_JOB_APPLICATION.pdf" target="blank">Download PDF Application</a>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default AboutUs;