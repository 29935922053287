import React from 'react';
import { Table } from 'react-bootstrap';

const TwoInchOpnToOneAndHalfOpn = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 2” OPN - 1 1/2 OPN</h4>
      <Table striped condensed hover>

        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>2" OPN</th>
            <td>.120</td>
            <td></td>
            <td></td>
            <td>.44</td>

          </tr>

          <tr>
            <th>2" OPN</th>
            <td>.135</td>
            <td></td>
            <td></td>
            <td>.55</td>

          </tr>

          <tr>
            <th>2" OPN</th>
            <td>.148</td>
            <td></td>
            <td></td>
            <td>.65</td>
          </tr>

          <tr>
            <th>2" OPN</th>
            <td>.162</td>
            <td></td>
            <td></td>
            <td>.78</td>
          </tr>

          <tr>
            <th>2" OPN</th>
            <td>.192</td>
            <td></td>
            <td></td>
            <td>1.08</td>
          </tr>

          <tr>
            <th>2" OPN</th>
            <td>.250</td>
            <td></td>
            <td></td>
            <td>1.79</td>
          </tr>

          <tr>
            <th>1 3/4" OPN</th>
            <td>.120</td>
            <td></td>
            <td></td>
            <td>.49</td>
          </tr>

          <tr>
            <th>1 3/4" OPN</th>
            <td>.135</td>
            <td></td>
            <td></td>
            <td>.62</td>
          </tr>

          <tr>
            <th>1 3/4" OPN</th>
            <td>.148</td>
            <td></td>
            <td></td>
            <td>.74</td>
          </tr>

          <tr>
            <th>1 3/4" OPN</th>
            <td>.162</td>
            <td></td>
            <td></td>
            <td>.88</td>
          </tr>

          <tr>
            <th>1 3/4" OPN</th>
            <td>.192</td>
            <td></td>
            <td></td>
            <td>1.22</td>
          </tr>

          <tr>
            <th>1 3/4" OPN</th>
            <td>.250</td>
            <td></td>
            <td></td>
            <td>2.02</td>
          </tr>

          <tr>
            <th>1 1/2" OPN</th>
            <td>.120</td>
            <td></td>
            <td></td>
            <td>.57</td>
          </tr>

          <tr>
            <th>1 1/2" OPN</th>
            <td>.135</td>
            <td></td>
            <td></td>
            <td>.72</td>
          </tr>

          <tr>
            <th>1 1/2" OPN</th>
            <td>.148</td>
            <td></td>
            <td></td>
            <td>.85</td>
          </tr>

          <tr>
            <th>1 1/2" OPN</th>
            <td>.162</td>
            <td></td>
            <td></td>
            <td>1.02</td>
          </tr>

          <tr>
            <th>1 1/2" OPN</th>
            <td>.192</td>
            <td></td>
            <td></td>
            <td>1.40</td>
          </tr>

          <tr>
            <th>1 1/2" OPN</th>
            <td>.250</td>
            <td></td>
            <td></td>
            <td>2.31</td>
          </tr>

          <tr>
            <th>1 1/4" OPN</th>
            <td>.120</td>
            <td></td>
            <td></td>
            <td>.68</td>
          </tr>

          <tr>
            <th>1 1/2" OPN</th>
            <td>.135</td>
            <td></td>
            <td></td>
            <td>.85</td>
          </tr>

          <tr>
            <th>1 1/2" OPN</th>
            <td>.148</td>
            <td></td>
            <td></td>
            <td>1.01</td>
          </tr>

        </tbody>
      </Table>
    </div>
  )
}

export default TwoInchOpnToOneAndHalfOpn;