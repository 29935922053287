import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Col, FormGroup, FormControl, Button } from 'react-bootstrap';
import Layout from '../layout/Layout';
// import { SignUpLink } from '../signUp';
import { PasswordForgetLink } from '../passwordForget';
import { auth } from '../../firebase';
import * as routes from '../../constants/routes';
import { RequestAccessLink } from '../requestAccess';
//import psmLogoWhite from '../../img/logo_white.svg';

const SignInPage = ({ history }) =>
    <div className="login-wrapper">
    <Layout>
      <Grid>
        <Col xsHidden md={3}></Col>
        <Col md={6} className="login-modal">
        <h3 className="login-text">Log in to you account</h3>
        <div className="login-well">
          <SignInForm history={history} />
          <PasswordForgetLink />
          {/* <RequestAccessLink/> */}
        </div>
        </Col>
      </Grid>
    </Layout>
  </div>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        history.push(routes.DASHBOARD);
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <FormGroup bsSize="large">
      <form onSubmit={this.onSubmit}>
      <FormGroup bsSize="large">
        <FormControl
          value={email}
          onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
          type="text"
          placeholder="Email Address"
        />
        </FormGroup>
        <FormGroup bsSize="large">
        <FormControl
          value={password}
          onChange={event => this.setState(updateByPropertyName('password', event.target.value))}
          type="password"
          placeholder="Password"
        />
        </FormGroup>
        <Button bsStyle="primary" bsSize="large" className="btn-blank btn-blue" disabled={isInvalid} type="submit">
          Sign In
        </Button>

        { error && <p>{error.message}</p> }
      </form>
      </FormGroup>
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};
