import React from 'react';
import { Table } from 'react-bootstrap';

const ThirtyMeshToEightyMesh = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 30 Mesh - 80 Mesh</h4>
      <Table striped condensed hover>
      
        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>30X30</th>
            <td>.012</td>
            <td>.0213</td>
            <td>40.8</td>
            <td>.294</td>
          </tr>

          <tr>
            <th>30X30</th>
            <td>.013</td>
            <td>.0203</td>
            <td>37.1</td>
            <td>.348</td>
          </tr>

          <tr>
            <th>30X30</th>
            <td>.0135</td>
            <td>.0198</td>
            <td>35.3</td>
            <td>.378</td>
          </tr>

          <tr>
            <th>30X30</th>
            <td>.014</td>
            <td>.0193</td>
            <td>33.5</td>
            <td>.408</td>
          </tr>

          <tr>
            <th>30X30</th>
            <td>.015</td>
            <td>.0183</td>
            <td>30.1</td>
            <td>.474</td>
          </tr>

          <tr>
            <th>30X30</th>
            <td>.016</td>
            <td>.0173</td>
            <td>26.9</td>
            <td>.618</td>
          </tr>

          <tr>
            <th>35X35</th>
            <td>.011</td>
            <td>.0176</td>
            <td>37.9</td>
            <td>.290</td>
          </tr>

          <tr>
            <th>35X35</th>
            <td>.012</td>
            <td>.0166</td>
            <td>33.8</td>
            <td>.350</td>
          </tr>

          <tr>
            <th>35X35</th>
            <td>.013</td>
            <td>.0156</td>
            <td>29.8</td>
            <td>.416</td>
          </tr>

          <tr>
            <th>35X35</th>
            <td>.014</td>
            <td>.0146</td>
            <td>26.1</td>
            <td>.465</td>
          </tr>

          <tr>
            <th>40X36</th>
            <td>.0095</td>
            <td>.0155/.0183</td>
            <td>40.8</td>
            <td>.224</td>
          </tr>

          <tr>
            <th>40X36</th>
            <td>.010</td>
            <td>.015/.0178</td>
            <td>38.5</td>
            <td>.243</td>
          </tr>

          <tr>
            <th>40X40</th>
            <td>.008</td>
            <td>.017</td>
            <td>46.2</td>
            <td>.172</td>
          </tr>

          <tr>
            <th>40X40</th>
            <td>.0085</td>
            <td>.0165</td>
            <td>43.6</td>
            <td>.195</td>
          </tr>

          <tr>
            <th>40X40</th>
            <td>.009</td>
            <td>.016</td>
            <td>41.0</td>
            <td>.220</td>
          </tr>

          <tr>
            <th>40X40</th>
            <td>.095</td>
            <td>.0155</td>
            <td>38.4</td>
            <td>.247</td>
          </tr>

          <tr>
            <th>40X40</th>
            <td>.010</td>
            <td>.015</td>
            <td>36.0</td>
            <td>.276</td>
          </tr>

          <tr>
            <th>40X40</th>
            <td>.012</td>
            <td>.013</td>
            <td>27.0</td>
            <td>.409</td>
          </tr>

          <tr>
            <th>40X40</th>
            <td>.013</td>
            <td>.012</td>
            <td>23.0</td>
            <td>.488</td>
          </tr>

          <tr>
            <th>45X45</th>
            <td>.008</td>
            <td>.0142</td>
            <td>40.8</td>
            <td>.196</td>
          </tr>

          <tr>
            <th>50X40</th>
            <td>.008</td>
            <td>.012/.017</td>
            <td>40.8</td>
            <td>.196</td>
          </tr>

          <tr>
            <th>50X50</th>
            <td>.008</td>
            <td>.012</td>
            <td>36.0</td>
            <td>.221</td>
          </tr>

          <tr>
            <th>50X50</th>
            <td>.0085</td>
            <td>.0115</td>
            <td>33.1</td>
            <td>.251</td>
          </tr>

          <tr>
            <th>50X50</th>
            <td>.009</td>
            <td>.011</td>
            <td>30.3</td>
            <td>.284</td>
          </tr>

          <tr>
            <th>60X40</th>
            <td>.0065</td>
            <td>.0102/.0185</td>
            <td>45.3</td>
            <td>.139</td>
          </tr>


          <tr>
            <th>60X40</th>
            <td>.0009</td>
            <td>.007/.016</td>
            <td>29.6</td>
            <td>.270</td>
          </tr>

          <tr>
            <th>60X50</th>
            <td>.0065</td>
            <td>.0102/.0135</td>
            <td>41.3</td>
            <td>.153</td>
          </tr>

          <tr>
            <th>60X50</th>
            <td>.0075</td>
            <td>.0092/.0125</td>
            <td>34.4</td>
            <td>.203</td>
          </tr>

          <tr>
            <th>60X60</th>
            <td>.0075</td>
            <td>.0092</td>
            <td>30.5</td>
            <td>.237</td>
          </tr>

          <tr>
            <th>60X60</th>
            <td>.009</td>
            <td>.0077</td>
            <td>21.3</td>
            <td>.335</td>
          </tr>

          <tr>
            <th>60X60</th>
            <td>.011</td>
            <td>.0057</td>
            <td>11.7</td>
            <td>.529</td>
          </tr>

          <tr>
            <th>70X70</th>
            <td>.0065</td>
            <td>.0078</td>
            <td>29.8</td>
            <td>.208</td>
          </tr>

          <tr>
            <th>80X80</th>
            <td>.0055</td>
            <td>.007/.0088</td>
            <td>34.5</td>
            <td>.150</td>
          </tr>

          <tr>
            <th>80X80</th>
            <td>.007</td>
            <td>.0055/.0073</td>
            <td>22.5</td>
            <td>.248</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default ThirtyMeshToEightyMesh;
