export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const DASHBOARD = '/dashboard';
export const ACCOUNT = '/account';
export const REQUEST_ACCESS = '/request-access'


export const WEBSTORE = '/store';
export const NEW_PRODUCT = '/new-product';
export const WEBSTORE_PRODUCTS_DETAIL = '/store/product/:id';
export const PRODUCTS = '/products';
export const INDUSTRIES = '/industries';
export const BLOG = '/blog';
export const CONTACT = '/contact';
export const WEBINAR = '/webinar';
export const PAGENOTFOUND = '/404';

export const AGGREGATE = '/aggregate';
export const ARCHITECTURAL = '/architectural';
export const INDUSTRIAL = '/industrial';
export const REFININGPETROCHEM = '/refining-petro-chem';

export const WOVENWIREMESH = '/woven-wire-mesh/two-inch-opn-to-one-and-half-opn';
export const WELDEDWIREMESH = '/welded-wire-mesh';
export const PERFORATEDMETAL = '/perforated-metal';
export const TIEWIRE = '/tie-wire';
export const HARDWARECLOTH = '/hardware-cloth';
export const FIBERGLASSGRATING = '/fiberglass-grating';
export const SAFETYGRATING = '/safety-grating';
export const EXPANDEDMETAL = '/expanded-metal';
export const BARGRATING = '/bar-grating';
export const REACTORINTERNALS = '/reactor-internals';

export const DOWNLOADS = '/downloads';
export const SHIPPING = '/shipping-and-crating';
export const MATERIALS = '/materials';
export const CHARTS = '/charts';
export const CREDITAPPLICATION = '/credit-application';
export const ABOUT = '/about-us';
export const JOBS = '/jobs';
export const TERMS = '/terms';

