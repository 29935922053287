import { firebase } from '../firebase/firebase'

export const login = (user) => ({
  type: 'LOGIN',
  user
});

export const signInSuccess = (user) => ({
  type: 'SIGN_IN_SUCCESS', 
  user
});


export const logout = () => ({
  type: 'LOGOUT'
})
export const startLogout = () => {
  return () => {
    return firebase.auth().signOut();
  }
}