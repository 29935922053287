import React from 'react';
import { Table } from 'react-bootstrap';

const OneInchMesh = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 1&#8243; Mesh</h4>
      <Table striped condensed hover>

        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.063</td>
            <td>.937</td>
            <td>87.8</td>
            <td>.255</td>

          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.080</td>
            <td>.920</td>
            <td>84.6</td>
            <td>.411</td>

          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.092</td>
            <td>.908</td>
            <td>82.4</td>
            <td>.544</td>
          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.105</td>
            <td>.895</td>
            <td>80.1</td>
            <td>.710</td>
          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.120</td>
            <td>.880</td>
            <td>77.4</td>
            <td>.928</td>
          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.135</td>
            <td>.865</td>
            <td>74.8</td>
            <td>1.177</td>
          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.148</td>
            <td>.852</td>
            <td>72.6</td>
            <td>1.417</td>
          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.162</td>
            <td>.838</td>
            <td>70.2</td>
            <td>1.702</td>
          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.192</td>
            <td>.808</td>
            <td>65.3</td>
            <td>2.403</td>
          </tr>

          <tr>
            <th>1&#8243; X 1&#8243;</th>
            <td>.250</td>
            <td>.750</td>
            <td>56.3</td>
            <td>4.124</td>
          </tr>
        </tbody>

      </Table>
    </div>
  )
}

export default OneInchMesh;