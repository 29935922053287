import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { Grid, Button  } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';
import image1 from './../../img/product-img/psm_reactor_internals.png';

const ReactorInternals = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div>
            <Grid>
              <center><h2>Reactor Internals</h2> <h4>Contact our team for more details</h4></center>
              <center>
                <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
                <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_REACTOR_INTERNALS.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
              </center>
            </Grid>
            <img src={image1} alt="Reator internals screens" className="img-responsive"/>
          </div>
        </Grid>
      </Layout>
    </div>
  )
}

export default ReactorInternals;