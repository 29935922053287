import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { Grid, Row, Col, Button  } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';

import BarGratingChart from './BarGrating/BarGratingChart'

import image1 from './../../img/product-img/bar_grating/BarGrating3.jpg';
import image2 from './../../img/product-img/bar_grating/BarGrating2.jpg';
import image3 from './../../img/product-img/bar_grating/BarGrating4.png';

const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <Col md={4}>
        <img src={image1} alt="BarGrating3" draggable={false} className="img-responsive" />
        <img src={image2} alt="Street Grate #1" draggable={false} className="img-responsive" />
        <img src={image3} alt="BarGrating4" draggable={false} className="img-responsive" />
      </Col>
      <Col md={8}>
        <h3>Bar Grating</h3>
        <p>
          "Used universally for walkway and catwalk decking applications. Ideal for most commercial and industry uses."
          We can offer a wide variety of panels from stock or custom fabricate to your needs. We offer steel, aluminum,
          also stair tread (with or without nosing), and fiberglass grating for your needs.</p>
        <h3>Welded Bar Grating Sizes and Weights</h3>
        <BarGratingChart />
      </Col>
    </div>
  )
}

const BarGrating = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="bar-grating-header">
            <Grid>
              <h2>Bar Grating</h2>
              <h4>Let us supply your bar grating needs.</h4>
              <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_BAR_GRATING.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
            <MeasuringAndOrderingInstructions />
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default BarGrating;