import React from 'react';
import Layout from '../layout/Layout';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Button } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';

import image1 from './../../img/product-img/fiberglass_grating/FiberglassGrating1.jpg';
import image2 from './../../img/product-img/fiberglass_grating/FiberglassGrating.jpg';
import image3 from './../../img/product-img/fiberglass_grating/FiberglassGrating2.jpg';

const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <Col md={4}>
      <img src={image2} alt="FiberglassGrating" draggable={false} className="img-fix" />
      </Col>
      <Col md={8}>
        <h3>Fiberglass Grating</h3>
        <p>Fiberglass reinforced plastics (frp) composites are strong, 
          lightweight, corrosion resistant, and virtually maintenance free. 
          Frp is desirable for a wide range of product applications. 
          Frp grating is a great alternative to metal where rust, chemicals, and corrosion exist.
          Please contact PSM and let us help with your requirements!</p>
          <Col md={6}><img src={image1} alt="FiberglassGrating1" draggable={false} className="img-fix" /></Col>
          <Col md={6}><img src={image3} alt="FiberglassGrating2" draggable={false} className="img-fix" /></Col>
      </Col>
    </div>
  )
}



const FiberglassGrating = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="fiberglass-grating-header">
            <Grid>
              <h2>Fiberglass Grating</h2>
              <h4>We offer any hardware and hold clip accessories you need!</h4>
              <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_FIBERGLASS_GRATING.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
            <MeasuringAndOrderingInstructions />
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default FiberglassGrating;




