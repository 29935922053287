import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const aggregateImg = 'https://s3.us-east-2.amazonaws.com/psm-webapp/assets/fulltruck2.jpg';

const Architectural = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={4}>
              <h1>Architectural</h1>
              <p>
                We are a wire cloth/screen manufacturer with weaving facilities in San Antonio and Tulsa. 
                We maintain a large inventory on many opens and wire diameters. 
                We also have the capabilities to weave all grades of stainless steel, most exotic alloys for the refining/petrochem industry, 
                galvanized wire, aluminum, and other products used for separating and screening. 
                We strive on the fastest lead times in the industry at competitive prices. Our weaving capabilities include lock crimp, 
                double crimp, and also smooth top crimp. We also produce a wide range of weave patterns including square mesh, 
                rectangular, and slotted openings. We are able to weave a wide variety of openings from 10 mesh to 4″ 
                square openings with a wide range of wire diameter. We would appreciate the opportunity to quote any requirements you may have.
            </p>
              <h3>Measuring and Ordering Material</h3>
              <p>1. Specify opening or mesh count, wire diameter and desired alloy.</p>
              <p>2. Specify overall dimensions of material, and quantity required.</p>
              <p>3. If ordering a slotted opening, the direction of the slot should be specified.</p>
            </Col>
            <Col md={8}>
              <img src={aggregateImg} alt="Architectural"  className="responsive-img" />
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Architectural;