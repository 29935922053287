import React from 'react';
import { Table } from 'react-bootstrap';

const PerforatedMetalChart = () => {
  return (
    <div>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th><h5>HOLE SIZE</h5></th>
            <th><h5>CENTERS</h5></th>
            <th><h5>GAUGES</h5></th>
            <th><h5>OPEN AREA %</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>1/32&#8243;</th>
            <td>1/16&#8243;</td>
            <td>22</td>
            <td>22%</td>

          </tr>

          <tr>
            <th>.050&#8243;</th>
            <td>.066&#8243; STR</td>
            <td>24</td>
            <td>45%</td>

          </tr>

          <tr>
            <th>1/16&#8243;</th>
            <td>3/32&#8243;</td>
            <td>20,18</td>
            <td>45%</td>
          </tr>

          <tr>
            <th>1/16&#8243;</th>
            <td>7/64&#8243;</td>
            <td>16</td>
            <td>30%</td>
          </tr>

          <tr>
            <th>1/16&#8243;</th>
            <td>1/8&#8243;</td>
            <td>20-18</td>
            <td>23%</td>
          </tr>

          <tr>
            <th>5/64&#8243;</th>
            <td>1/8&#8243;</td>
            <td>20-16</td>
            <td>36%</td>
          </tr>

          <tr>
            <th>3/32&#8243;</th>
            <td>5/32&#8243;</td>
            <td>20-16</td>
            <td>33%</td>
          </tr>

          <tr>
            <th>.117&#8243;</th>
            <td>5/32&#8243;</td>
            <td>18-16</td>
            <td>51%</td>
          </tr>

          <tr>
            <th>1/8&#8243;</th>
            <td>3/16&#8243;</td>
            <td>22-11</td>
            <td>40%</td>
          </tr>

          <tr>
            <th>1/8&#8243;</th>
            <td>7/32&#8243;</td>
            <td>16,14</td>
            <td>29%</td>
          </tr>

          <tr>
            <th>1/8&#8243;</th>
            <td>7/32&#8243;</td>
            <td>16,14</td>
            <td>29%</td>
          </tr>

          <tr>
            <th>5/32&#8243;</th>
            <td>3/16&#8243;</td>
            <td>18-16</td>
            <td>63%</td>
          </tr>

          <tr>
            <th>5/32&#8243;</th>
            <td>1/4&#8243;</td>
            <td>20-14</td>
            <td>36%</td>
          </tr>

          <tr>
            <th>3/16&#8243;</th>
            <td>7/32&#8243;</td>
            <td>20-16</td>
            <td>67%</td>
          </tr>

          <tr>
            <th>3/16&#8243;</th>
            <td>1/4&#8243;</td>
            <td>20-14</td>
            <td>51%</td>
          </tr>

          <tr>
            <th>3/16&#8243;</th>
            <td>5/16&#8243;</td>
            <td>16-3/16&#8243;THK</td>
            <td>33%</td>
          </tr>

          <tr>
            <th>1/4&#8243;</th>
            <td>HEX</td>
            <td>20,22</td>
            <td>79%</td>
          </tr>

          <tr>
            <th>1/4&#8243;</th>
            <td>5/16&#8243;</td>
            <td>20-14</td>
            <td>58%</td>
          </tr>

          <tr>
            <th>1/4&#8243;</th>
            <td>3/8&#8243;</td>
            <td>18-1/4&#8243;THK</td>
            <td>40%</td>
          </tr>

          <tr>
            <th>1/4&#8243;</th>
            <td>3/8&#8243;STR</td>
            <td>16-11</td>
            <td>35%</td>
          </tr>

          <tr>
            <th>1/4&#8243;</th>
            <td>1/2&#8243;STR</td>
            <td>16-11</td>
            <td>23%</td>
          </tr>

          <tr>
            <th>5/16&#8243;</th>
            <td>7/16&#8243;STR</td>
            <td>16-1/4&#8243;THK</td>
            <td>50%</td>
          </tr>

          <tr>
            <th>3/8&#8243;</th>
            <td>9/16&#8243;</td>
            <td>16-1/4&#8243;THK</td>
            <td>40%</td>
          </tr>

          <tr>
            <th>1/2&#8243;</th>
            <td>11/16&#8243;</td>
            <td>16-1/4&#8243;THK</td>
            <td>48%</td>
          </tr>

          <tr>
            <th>5/8&#8243;</th>
            <td>7/8&#8243;</td>
            <td>14,11</td>
            <td>46%</td>
          </tr>

          <tr>
            <th>3/4&#8243;</th>
            <td>1&#8243;</td>
            <td>16-1/4&#8243;THK</td>
            <td>51%</td>
          </tr>

          <tr>
            <th>1&#8243;</th>
            <td>1-3/8&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>58%</td>
          </tr>

          <tr>
            <th>1&#8243;</th>
            <td>1-3/8&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>48%</td>
          </tr>

          <tr>
            <th>1-1/4&#8243;</th>
            <td>1-5/8&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>54%</td>
          </tr>

          <tr>
            <th>1-1/2&#8243;</th>
            <td>2&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>51%</td>
          </tr>

          <tr>
            <th>1-1/2&#8243;</th>
            <td>2&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>51%</td>
          </tr>

          <tr>
            <th>2&#8243;</th>
            <td>2-1/2&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>58%</td>
          </tr>

          <tr>
            <th>2-1/2&#8243;</th>
            <td>3&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>63%</td>
          </tr>

          <tr>
            <th>3&#8243;</th>
            <td>3-1/2&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>67%</td>
          </tr>

          <tr>
            <th>3-1/2&#8243;</th>
            <td>4&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>70%</td>
          </tr>

          <tr>
            <th>4&#8243;</th>
            <td>4-1/2&#8243;</td>
            <td>11-1/4&#8243;THK</td>
            <td>72%</td>
          </tr>

        </tbody>
      </Table>
    </div>
  )
}

export default PerforatedMetalChart;
