import React from 'react';
import { Table } from 'react-bootstrap';

const ExpandedMetalChart = () => {
  return (
    <div>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th><h5>STYLE</h5></th>
            <th><h5>LBS PER 100 SF</h5></th>
            <th><h5>STANDARD SHEET SIZE</h5></th>
            <th><h5>DESIGN SIZES INCHES</h5></th>
            <th></th>
            <th><h5>OVERALL THICKNESS</h5></th>
            <th><h5>% OPEN AREA</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th></th>
            <td></td>
            <td></td>
            <td>SWD</td>
            <td>LWD</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <th>1/4&#8243; X #20</th>
            <td>83</td>
            <td>48" X 96"</td>
            <td>0.25</td>
            <td>1.03</td>
            <td>0.03</td>
            <td>47</td>

          </tr>

          <tr>
            <th>1/4" X #18</th>
            <td>111</td>
            <td>48" X 96"</td>
            <td>0.25</td>
            <td>1.03</td>
            <td>0.04</td>
            <td>40</td>

          </tr>

          <tr>
            <th>1/2" X #20</th>
            <td>40</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.26</td>
            <td>0.029</td>
            <td>72</td>

          </tr>

          <tr>
            <th>1/2" X #18</th>
            <td>66</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.26</td>
            <td>0.039</td>
            <td>69</td>
          </tr>

          <tr>
            <th>1/2" X #16</th>
            <td>82</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.26</td>
            <td>0.05</td>
            <td>60</td>
          </tr>

          <tr>
            <th>1/2"X #13</th>
            <td>140</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.26</td>
            <td>0.07</td>
            <td>57</td>
          </tr>

          <tr>
            <th>1/2"X #16</th>
            <td>51</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.1</td>
            <td>0.048</td>
            <td>75</td>
          </tr>

          <tr>
            <th>3/4" X #13</th>
            <td>51</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.1</td>
            <td>0.048</td>
            <td>75</td>
          </tr>

          <tr>
            <th>3/4" X #9</th>
            <td>171</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.1</td>
            <td>0.12</td>
            <td>63</td>
          </tr>

          <tr>
            <th>1" X #16</th>
            <td>41</td>
            <td>48" X 96"</td>
            <td>1.09</td>
            <td>2.56</td>
            <td>0.048</td>
            <td>77</td>
          </tr>

          <tr>
            <th>1-1/2" X #16</th>
            <td>38</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3.2</td>
            <td>0.048</td>
            <td>82</td>
          </tr>

          <tr>
            <th>1-1/2" X #13</th>
            <td>57</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3.2</td>
            <td>0.07</td>
            <td>80</td>
          </tr>

          <tr>
            <th>1-1/2" X #9</th>
            <td>111</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3.2</td>
            <td>0.11</td>
            <td>77</td>
          </tr>


          <tr>
            <th>FLATTENED EXPANDED STAINLESS STEEL</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <th>1/2" X #18</th>
            <td>69</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.25</td>
            <td>0.04</td>
            <td>68</td>
          </tr>

          <tr>
            <th>1/2" X #16</th>
            <td>86</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.25</td>
            <td>0.05</td>
            <td>60</td>
          </tr>

          <tr>
            <th>1/2" X #13</th>
            <td>178</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.25</td>
            <td>0.08</td>
            <td>56</td>
          </tr>

          <tr>
            <th>3/4" X #16</th>
            <td>57</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.1</td>
            <td>0.05</td>
            <td>75</td>
          </tr>

          <tr>
            <th>3/4" X #13</th>
            <td>87</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.1</td>
            <td>0.07</td>
            <td>74</td>
          </tr>

          <tr>
            <th>3/4" X #9</th>
            <td>195</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.1</td>
            <td>0.119</td>
            <td>64</td>
          </tr>

          <tr>
            <th>1-1/2" X #16</th>
            <td>43</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3.2</td>
            <td>0.05</td>
            <td>83</td>
          </tr>

          <tr>
            <th>1-1/2" X #13</th>
            <td>65</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3.2</td>
            <td>0.079</td>
            <td>79</td>
          </tr>

          <tr>
            <th>1-1/2" X #9</th>
            <td>131</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3.2</td>
            <td>0.119</td>
            <td>76</td>
          </tr>

          <tr>
            <th>FLATTENED EXPANDED ALUMINUM</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <th>1/2" X .050</th>
            <td>25</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.27</td>
            <td>0.04</td>
            <td>57</td>
          </tr>

          <tr>
            <th>1/2" X .080</th>
            <td>41</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.27</td>
            <td>0.06</td>
            <td>57</td>
          </tr>


          <tr>
            <th>3/4" X .050</th>
            <td>16</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.12</td>
            <td>0.04</td>
            <td>73</td>
          </tr>

          <tr>
            <th>3/4" X .080</th>
            <td>30</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.12</td>
            <td>0.07</td>
            <td>66</td>
          </tr>

          <tr>
            <th>3/4" X .125</th>
            <td>61</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2.12</td>
            <td>0.095</td>
            <td>55</td>
          </tr>

          <tr>
            <th>1-1/2" X .080</th>
            <td>20</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3.15</td>
            <td>0.055</td>
            <td>75</td>
          </tr>

          <tr>
            <th>1-1/2" X .125</th>
            <td>40</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3.15</td>
            <td>0.08</td>
            <td>65</td>
          </tr>

          <tr>
            <th>STANDARD (RAISED) EXPANDED CARBON STEEL</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <th>1/4" X #1</th>
            <td>114</td>
            <td>48" X 96"</td>
            <td>0.25</td>
            <td>1</td>
            <td>0.125</td>
            <td>43</td>
          </tr>

          <tr>
            <th>1/2" X #18</th>
            <td>70</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.2</td>
            <td>0.155</td>
            <td>77</td>
          </tr>

          <tr>
            <th>1/2" X #16</th>
            <td>86</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.2</td>
            <td>0.157</td>
            <td>71</td>
          </tr>

          <tr>
            <th>1/2" X #13</th>
            <td>147</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.2</td>
            <td>0.182</td>
            <td>58</td>
          </tr>


          <tr>
            <th>3/4" X #13</th>
            <td>54</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.186</td>
            <td>85</td>
          </tr>


          <tr>
            <th>3/4" X #9</th>
            <td>80</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.195</td>
            <td>78</td>
          </tr>


          <tr>
            <th>3/4" X #9</th>
            <td>180</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.3</td>
            <td>66</td>
          </tr>


          <tr>
            <th>1" X #16</th>
            <td>44</td>
            <td>48" X 96"</td>
            <td>1.09</td>
            <td>2.4</td>
            <td>0.182</td>
            <td>86</td>
          </tr>

          <tr>
            <th>1-1/2" X #16</th>
            <td>40</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3</td>
            <td>0.211</td>
            <td>89</td>
          </tr>

          <tr>
            <th>1-1/2" X #13</th>
            <td>60</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3</td>
            <td>0.215</td>
            <td>86</td>
          </tr>

          <tr>
            <th>1-1/2" X #9</th>
            <td>120</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3</td>
            <td>0.295</td>
            <td>75</td>
          </tr>


          <tr>
            <th>STANDARD (RAISED) EXPANDED STAINLESS STEEL</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <th>1/2" X #18</th>
            <td>73</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.2</td>
            <td>0.164</td>
            <td>77</td>
          </tr>

          <tr>
            <th>1/2" X #16</th>
            <td>91</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.2</td>
            <td>0.164</td>
            <td>70</td>
          </tr>


          <tr>
            <th>1/2" X #13</th>
            <td>187</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.2</td>
            <td>0.225</td>
            <td>58</td>
          </tr>

          <tr>
            <th>3/4" X #16</th>
            <td>60</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.2</td>
            <td>85</td>
          </tr>

          <tr>
            <th>3/4" X #13</th>
            <td>91</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.2</td>
            <td>78</td>
          </tr>

          <tr>
            <th>3/4" X #9</th>
            <td>205</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.3</td>
            <td>67</td>
          </tr>

          <tr>
            <th>1-1/2" X #16</th>
            <td>45</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3</td>
            <td>0.22</td>
            <td>89</td>
          </tr>

          <tr>
            <th>1-1/2" X #13</th>
            <td>68</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3</td>
            <td>0.22</td>
            <td>86</td>
          </tr>

          <tr>
            <th>1-1/2" X #9</th>
            <td>137</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3</td>
            <td>0.28</td>
            <td>75</td>
          </tr>

          <tr>
            <th>STANDARD (RAISED) EXPANDED ALUMINUM</th>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <th>1/2" X .050</th>
            <td>27</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.2</td>
            <td>0.158</td>
            <td>70</td>
          </tr>

          <tr>
            <th>1/2" X .080</th>
            <td>44</td>
            <td>48" X 96"</td>
            <td>0.5</td>
            <td>1.2</td>
            <td>0.186</td>
            <td>60</td>
          </tr>

          <tr>
            <th>3/4" X .050</th>
            <td>17</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.2</td>
            <td>90</td>
          </tr>

          <tr>
            <th>3/4" X .080</th>
            <td>41</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.2</td>
            <td>76</td>
          </tr>

          <tr>
            <th>3/4" X .125</th>
            <td>65</td>
            <td>48" X 96"</td>
            <td>0.92</td>
            <td>2</td>
            <td>0.305</td>
            <td>66</td>
          </tr>

          <tr>
            <th>1-1/2" X .080</th>
            <td>22</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3</td>
            <td>0.24</td>
            <td>87</td>
          </tr>

          <tr>
            <th>1-1/2" X .125</th>
            <td>43</td>
            <td>48" X 96"</td>
            <td>1.33</td>
            <td>3</td>
            <td>0.3</td>
            <td>78</td>
          </tr>

        </tbody>
      </Table>
    </div >
  )
}

export default ExpandedMetalChart;
