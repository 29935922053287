import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Col } from 'react-bootstrap';

const Downloads = () => {
  return (
    <div>
      <Layout>
        <Grid>
          <Row className="show-grid">
            <Col md={12}>
              <h1>Downloads</h1>
              <div className="well">
                <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM-Catalog.pdf"
                  target="_blank" rel="noopener noreferrer">Download Catalog</a>
              </div>
              <div className="well">
                <a href="https://psm-webapp.s3.us-east-2.amazonaws.com/downloads/PSM_Linesheet.pdf"
                  target="_blank" rel="noopener noreferrer">Download Line Sheet</a>
              </div>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Downloads;