import database from "../firebase/firebase";
import firebase from "firebase";
import _ from 'lodash';

// const profile = 'https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/profile_images%2FQcroUjlQ16PvL2rUzmFKvBKCfuH3%2Fjessie_small.jpg?alt=media&token=58f28991-79da-493a-8c61-bd3af5baa238'
/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const SET_PRODUCTS = "SET_PRODUCTS";
export const FETCH_TIMELINE_PRODUCTS = "FETCH_TIMELINE_PRODUCTS";
export const FETCH_PROFILE_PRODUCTS = "FETCH_PROFILE_PRODUCTS";
export const FETCH_MORE_PRODUCTS = "FETCH_MORE_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const EDIT_PRODUCT = 'EDIT_PRODUCT';



/**
 |--------------------------------------------------
 | Fetch lessons for signed in user
 |--------------------------------------------------
 */


// SET LISTS
export const setLists = (lessons) => ({
  type: FETCH_TIMELINE_PRODUCTS,
  lessons
});

export const fetchLessons = () => {
  return (dispatch, getState) => {
    // const user = getState().auth.user;
    return database.ref(`lessons`).once("value").then(snapshot => {
      const lessons = [];

      snapshot.forEach(childSnapshot => {
        lessons.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        });
      });
      dispatch(setLists( _.reverse(lessons)));
    })
  };
}

/**
 |--------------------------------------------------
 | Fetch lessons for signed in user
 |--------------------------------------------------
 */


// GETS FRIEND OR PUBLIC PROFILE BY PASSING USERS UID // USED by publicProfile
export function fetchUserProfileLists(uid) {
  return dispatch => {
    firebase.database().ref(`users/${uid}/`).on("value", snapshot => {
      let payload = snapshot.val();
      dispatch({
        type: FETCH_PROFILE_PRODUCTS,
        payload
      });
    });
  }; 
}



// ADD_PRODUCT
export const addProduct = product => ({
  type: ADD_PRODUCT,
  product
});

export const startAddProduct = (productData = {}) => {
  const { currentUser } = firebase.auth();
  return dispatch => {

    const {
      createdAt = 0,
      currencyFormat = '$',
      currencyId = 'USD',
      displayName = firebase.auth().currentUser.displayName,
      hidden = false,
      quantity = 1,
      img_url = '',
      price = 0,
      product_type_name = '',
      productCategories = [],
      photoURL = firebase.auth().currentUser.photoURL,
      productNotes = '',
      productName = '',
      productAlloy = '',
      productOpn = '',
      productWire = '',
      productDims = '',
      manufacturer = '',
      id = '',
      uid = firebase.auth().currentUser.uid,
      file = [],
      files = [],
      filenames = [],
      downloadURLs = [],
      slug = '',
    } = productData;

    const product = { 
      currencyFormat,
      manufacturer,
      hidden,
      img_url,
      quantity,
      price,
      productCategories,
      product_type_name,
      currencyId,
      displayName,
      photoURL,
      productName,
      productAlloy,
      productOpn,
      productWire,
      productDims,
      productNotes,
      createdAt, 
      id,
      uid, 
      file,
      files,
      filenames,
      downloadURLs,
      slug
    };

    let updates = {};

    updates[`/products/${id}`] = product;
    return database.ref().update(updates).then(ref => {
        dispatch(
          addProduct({
            id: ref,
            ...product
          })
        );
      });
  };
};


// REMOVE_PRODUCT
export const removeProduct = ({ id } = {}) => ({
  type: REMOVE_PRODUCT,
  id
});

export const startRemoveProduct = ({ id, slug } = {}) => {
  const { currentUser } = firebase.auth();
  console.log(id, slug, "DELETE")
  return (dispatch) => {
    return database.ref(`users/${slug}/products/${id}`).remove().then(() => {
      dispatch(removeProduct({ id, slug }));
    }).then(() => {
      const followersRef = database.ref(`followers/${currentUser.uid}`)
      followersRef.on("value", snapshot => {
        const followers = Object.keys(snapshot.val())
        followers.forEach(key => database.ref(`/timeline/${key}/${id}`).remove().then(() => {
          dispatch(removeProduct({ id, slug }));
        }))
      })
    })

  }
}

// EDIT_PRODUCT
export const editProduct = (id, slug, updates) => ({
  type: EDIT_PRODUCT,
  id,
  updates,
  slug
});

export const startEditProduct = (id, slug, updates) => {
  return (dispatch,  getState) => {
    // const user = getState().auth.user;
    // return database.ref(`followers/${user.uid}`).once("value", snapshot => {
      // Read followers keys and turn into hash
      // const followers = Object.keys(snapshot.val())
      // Create object to send updated to
      let updates_ = {}
      // Loop through followers and send updates
      // followers.forEach(key => updates_[`/timeline/${key}/${id}`] = updates)
      // Sendd updates to Users own Lists
      updates_[`users/${slug}/products/${id}`] = updates
      // Send updates to Lists node
      // updates_[`/lessons/${id}`] = updates
      
      // updates_[`/timeline/${user.uid}/${id}`] = updates
      return database.ref().update(updates_).then(() => {
        dispatch(editProduct(id, updates));
      })

    // })
  
  };

};
