import React from 'react';
import { Table } from 'react-bootstrap';

const HalfInchToThreeEightsOfInch = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 1/2&#8243; Mesh - 3/8&#8243; OPN</h4>
      <Table striped condensed hover>

        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>1/2&#8243; OPN</th>
            <td>.162</td>
            <td>.5</td>
            <td>57.1</td>
            <td>2.61</td>

          </tr>

          <tr>
            <th>1/2&#8243; OPN</th>
            <td>.177</td>
            <td>.5</td>
            <td>54.5</td>
            <td>3.06</td>

          </tr>

          <tr>
            <th>1/2&#8243; OPN</th>
            <td>.192</td>
            <td>.5</td>
            <td>52.2</td>
            <td>3.54</td>
          </tr>

          <tr>
            <th>1/2&#8243; OPN</th>
            <td>.207</td>
            <td>.5</td>
            <td>49.8</td>
            <td>4.04</td>
          </tr>

          <tr>
            <th>1/2&#8243; OPN</th>
            <td>.225</td>
            <td>.5</td>
            <td>47.5</td>
            <td>4.68</td>
          </tr>

          <tr>
            <th>1/2&#8243; OPN</th>
            <td>.25</td>
            <td>.5</td>
            <td>44.4</td>
            <td>5.62</td>
          </tr>

          <tr>
            <th>1/2&#8243; OPN</th>
            <td>.312</td>
            <td>.5</td>
            <td>37.9</td>
            <td>8.24</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.063</td>
            <td>.437</td>
            <td>76.4</td>
            <td>.51</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.072</td>
            <td>.437</td>
            <td>73.7</td>
            <td>.66</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.08</td>
            <td>.437</td>
            <td>71.5</td>
            <td>.8</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.092</td>
            <td>.437</td>
            <td>68.3</td>
            <td>1.04</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.105</td>
            <td>.437</td>
            <td>65</td>
            <td>1.33</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.12</td>
            <td>.437</td>
            <td>61.5</td>
            <td>1.69</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.135</td>
            <td>.437</td>
            <td>58.4</td>
            <td>2.09</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.148</td>
            <td>.437</td>
            <td>55.8</td>
            <td>2.47</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.162</td>
            <td>.437</td>
            <td>53.2</td>
            <td>2.9</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.177</td>
            <td>.437</td>
            <td>50.7</td>
            <td>3.4</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.192</td>
            <td>.437</td>
            <td>48.3</td>
            <td>3.92</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.207</td>
            <td>.437</td>
            <td>46</td>
            <td>4.47</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.225</td>
            <td>.437</td>
            <td>43.6</td>
            <td>5.16</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.25</td>
            <td>.437</td>
            <td>40.5</td>
            <td>6.19</td>
          </tr>

          <tr>
            <th>7/16&#8243; OPN</th>
            <td>.312</td>
            <td>.437</td>
            <td>34</td>
            <td>9.03</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.054</td>
            <td>.375</td>
            <td>76.4</td>
            <td>.44</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.063</td>
            <td>.375</td>
            <td>73.3</td>
            <td>.59</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.072</td>
            <td>.375</td>
            <td>70.4</td>
            <td>.75</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.08</td>
            <td>.375</td>
            <td>67.9</td>
            <td>.91</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.092</td>
            <td>.375</td>
            <td>64.5</td>
            <td>1.18</td>
          </tr>

          <tr>
            <th>1/2&#8243; OPN</th>
            <td>.105</td>
            <td>.375</td>
            <td>61</td>
            <td>1.51</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.12</td>
            <td>.375</td>
            <td>57.4</td>
            <td>1.92</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.135</td>
            <td>.375</td>
            <td>54.1</td>
            <td>2.37</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.148</td>
            <td>.375</td>
            <td>51.4</td>
            <td>2.79</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.162</td>
            <td>.375</td>
            <td>48.7</td>
            <td>3.27</td>
          </tr>

          <tr>
            <th>3/8&#8243; OPN</th>
            <td>.177</td>
            <td>.375</td>
            <td>46.1</td>
            <td>3.82</td>
          </tr>

        </tbody>
      </Table>
    </div>
  )
}

export default HalfInchToThreeEightsOfInch;