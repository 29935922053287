import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import { connect } from "react-redux";
import logo from "./../../img/logo.svg";
import * as routes from "../../constants/routes";
// import SignOutButton from '../signOut';
import { auth } from '../../firebase';

const Navigation = ({ authUser }) => (
  <div>{authUser ? <NavigationAuth /> : <NavigationNonAuth />}</div>
);

const NavigationAuth = () => (
  <div>
    <Navbar collapseOnSelect>
      <Navbar.Header>
        <Link to="/">
          <img src={logo} className="app-logo" alt="logo" />
        </Link>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight activeKey={1}>
          <NavItem componentClass="span">
            <Link to={routes.HOME}>Home</Link>
          </NavItem>
          <NavDropdown title="Products" id="basic-nav-dropdown">
            <MenuItem eventKey={2.1} componentClass="span">
              <NavLink to="/woven-wire-mesh/two-inch-opn-to-one-and-half-opn">
                Woven Wire Mesh
              </NavLink>
            </MenuItem>
            <MenuItem eventKey={2.2} componentClass="span">
              <NavLink to="/welded-wire-mesh">Welded Wire Mesh</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.3} componentClass="span">
              <NavLink to="/perforated-metal">Perforated Metal</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.4} componentClass="span">
              <NavLink to="/tie-wire">Tie Wire</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.5} componentClass="span">
              <NavLink to="/hardware-cloth">Hardware Cloth</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.6} componentClass="span">
              <NavLink to="/fiberglass-grating">Fiberglass Grating</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.7} componentClass="span">
              <NavLink to="/safety-grating">Safety Grating</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.8} componentClass="span">
              <NavLink to="/expanded-metal">Expanded Metal</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.9} componentClass="span">
              <NavLink to="/bar-grating">Bar Grating</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.1} componentClass="span">
              <NavLink to="/reactor-internals">Reactor Internals</NavLink>
            </MenuItem>
          </NavDropdown>
          <NavItem componentClass="span">
            <NavLink to="/store" activeClassName="nav-selected">
              Store
            </NavLink>
          </NavItem>
          <NavItem componentClass="span">
            <NavLink to="/contact" activeClassName="nav-selected">
              Contact
            </NavLink>
          </NavItem>
          <NavDropdown title="Dashboard" id="basic-nav-dropdown">
          <NavItem componentClass="span">
            <Link to={routes.DASHBOARD}>Dashboard</Link>
          </NavItem>
          <NavItem componentClass="span">
            <Link to={routes.NEW_PRODUCT}>New Product</Link>
          </NavItem>
          <NavItem componentClass="span">
            <Link to={routes.ACCOUNT}>Account</Link>
          </NavItem>
          <NavItem componentClass="span">
            <Link to="" onClick={auth.doSignOut}>Sign Out</Link>
          </NavItem>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
);

const NavigationNonAuth = () => (
  <div>
    <Navbar collapseOnSelect>
      <Navbar.Header>
        <Link to="/">
          <img src={logo} className="app-logo" alt="logo" />
        </Link>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight activeKey={1}>
          <NavItem eventKey={1} componentClass="span">
            <Link to={routes.HOME}>Home</Link>
          </NavItem>
          <NavDropdown title="Products" id="basic-nav-dropdown">
            <MenuItem eventKey={2.1} componentClass="span">
              <NavLink to="/woven-wire-mesh/two-inch-opn-to-one-and-half-opn">
                Woven Wire Mesh
              </NavLink>
            </MenuItem>
            <MenuItem eventKey={2.2} componentClass="span">
              <NavLink to="/welded-wire-mesh">Welded Wire Mesh</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.3} componentClass="span">
              <NavLink to="/perforated-metal">Perforated Metal</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.4} componentClass="span">
              <NavLink to="/tie-wire">Tie Wire</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.5} componentClass="span">
              <NavLink to="/hardware-cloth">Hardware Cloth</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.6} componentClass="span">
              <NavLink to="/fiberglass-grating">Fiberglass Grating</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.7} componentClass="span">
              <NavLink to="/safety-grating">Safety Grating</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.8} componentClass="span">
              <NavLink to="/expanded-metal">Expanded Metal</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.9} componentClass="span">
              <NavLink to="/bar-grating">Bar Grating</NavLink>
            </MenuItem>
            <MenuItem eventKey={2.1} componentClass="span">
              <NavLink to="/reactor-internals">Reactor Internals</NavLink>
            </MenuItem>
          </NavDropdown>
          {/* <NavItem componentClass="span">
            <NavLink to="/store" activeClassName="nav-selected">
              Store
            </NavLink>
          </NavItem> */}
          <NavItem componentClass="span">
            <NavLink to="/contact" activeClassName="nav-selected">
              Contact
            </NavLink>
          </NavItem>
          <NavItem componentClass="span">
            <Link to={routes.SIGN_IN}>Sign In</Link>
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

export default connect(mapStateToProps)(Navigation);
