import React from 'react';
import { Table } from 'react-bootstrap';

const TwoMesh = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 2 Mesh</h4>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>2 X 2</th>
            <td>.047</td>
            <td>.453</td>
            <td>82.1</td>
            <td>.284</td>
          </tr>

          <tr>
            <th>2 X 2</th>
            <td>.054</td>
            <td>.446</td>
            <td>79.6</td>
            <td>.376</td>
          </tr>

          <tr>
            <th>2 X 2</th>
            <td>.063</td>
            <td>.437</td>
            <td>76.4</td>
            <td>.512</td>
          </tr>

          <tr>
            <th>2 X 2</th>
            <td>.072</td>
            <td>.428</td>
            <td>73.3</td>
            <td>.671</td>
          </tr>

          <tr>
            <th>2 X 2</th>
            <td>.080</td>
            <td>.420</td>
            <td>70.6</td>
            <td>.830</td>
          </tr>

          <tr>
            <th>2 X 2</th>
            <td>.092</td>
            <td>.408</td>
            <td>66.6</td>
            <td>1.102</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default TwoMesh;