import React from 'react';

import { Grid, Col, Button } from 'react-bootstrap';

const StoreHeader = () => {
  return (
    <div className="webstore-cta-container">
      <Grid>
        <Col>
          <div>
            <h1 style={{color: '#FFFFFF'}}>Online Store</h1>
            <h3 className="sub-heading" style={{color: '#FFFFFF'}} >Check out our products in stock</h3>
          </div>
        </Col>
      </Grid>
    </div>
  )
}

export default StoreHeader;