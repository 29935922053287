import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

const MissionStatements = () => {
  return (
    <div className="mt-30">
      <Grid>
        <center>
          <h3 className="sub-heading">OUR MISSION</h3>
          <h1 className="section-heading">Manufacturing, Fabrication and Machining Capabilities</h1>
        </center>
        <Row className="show-grid">
          <Col xs={12} md={4}>
            <Col xs={1} md={2}>
              <div className="icon-size">
                <i className="fas fa-cogs fa-2x"></i>
              </div>
            </Col>
            <Col xs={11} md={10}>
            <h3 className="sub-heading">CAPABILITIES</h3>
              <p>We cater to the petrochemical/refining, construction, agricultural, steel fabrication, construction, water treatment, and architectural industries.</p>
            </Col>
          </Col>
          <Col xs={12} md={4}>
            <Col xs={1} md={2}>
              <div className="icon-size">
                <i className="far fa-calendar-check fa-2x"></i>
              </div>
            </Col>
            <Col xs={11} md={10}>
            <h3 className="sub-heading">DEPENDABLE</h3>
              <p>Our primary focus is customer service and finding a fast, dependable solution to your requirement.</p>
            </Col>
          </Col>
          <Col xs={12} md={4}>
            <Col xs={1} md={2}>
              <div className="icon-size">
                <i className="fas fa-shipping-fast fa-2x"></i>
              </div>
            </Col>
            <Col xs={11} md={10}>
            <h3 className="sub-heading">DELIVERABILITY</h3>
              <p>Our weaving capabilities are one of the largest in North America.</p>
            </Col>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col xs={12} md={4}>
            <Col xs={1} md={2}>
              <div className="icon-size">
                <i className="far fa-clock fa-2x"></i>
              </div>
            </Col>
            <Col xs={11} md={10}>
            <h3 className="sub-heading">ON-STANDBY</h3>
              <p>We offer fast lead times at a competitive price and are available 24/7 for your emergency requirements.</p>
            </Col>
          </Col>
          <Col xs={12} md={4}>
            <Col xs={1} md={2}>
              <div className="icon-size">
                <i className="fas fa-clipboard-list fa-2x"></i>
              </div>
            </Col>
            <Col xs={11} md={10}>
            <h3 className="sub-heading">COVERAGE</h3>
              <p>We can also supply any perforated metal, expanded metal, bar grating, and tie wire requirements.</p>
            </Col>
          </Col>
          <Col xs={12} md={4}>
            <Col xs={1} md={2}>
              <div className="icon-size">
                <i className="fas fa-wrench fa-2x"></i>
              </div>
            </Col>
            <Col xs={11} md={10}>
            <h3 className="sub-heading">SOLUTIONS</h3>
              <p>Largest range of custom mesh specifications in all alloys from Plain Steel, Galv, 304L/316L, 317, 321, 310, 330, 347, Monel, Inconel, and most exotic alloys for the refining/petrochem industry.</p>
            </Col>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default MissionStatements;