import React, { Component } from 'react';
import Navigation from '../navigation/index.js';
import Footer from '../../components/layout/Footer.js';

// Style sheet
import '../../styles/App.css';

class Layout extends Component {
  render() {
    return (
      <div className="app">
        <Navigation/>
          {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default Layout;
