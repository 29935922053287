import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Col, FormGroup, FormControl, Button } from "react-bootstrap";
import Layout from '../layout/Layout';

import { auth, db } from '../../firebase';
import * as routes from '../../constants/routes';

const RequestAccess = ({ history }) =>
  <div className="login-wrapper">
    <Layout>
      <Grid>
        <Col xsHidden md={3}></Col>
        <Col md={6} className="login-modal">
        <h3 className="login-text">Request access to account</h3>
        <div className="login-well">
          <SignUpForm history={history} />
        </div>
        </Col>
      </Grid>
    </Layout>
  </div>


const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};


class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      username,
      email,
      passwordOne,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {

        // Create a user in your own accessible Firebase Database too
        db.doCreateUser(authUser.user.uid, username, email)
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE }));
            history.push(routes.DASHBOARD);
          })
          .catch(error => {
            this.setState(updateByPropertyName('error', error));
          });

      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      username === '' ||
      email === '';

    return (
      <FormGroup bsSize="large">
      <form onSubmit={this.onSubmit}>
      <FormGroup bsSize="large">
        <FormControl 
          value={username}
          onChange={event => this.setState(updateByPropertyName('username', event.target.value))}
          type="text"
          placeholder="Full Name"
        />
      </FormGroup>
      <FormGroup bsSize="large">
        <FormControl
          value={email}
          onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
          type="text"
          placeholder="Email Address"
        />
      </FormGroup>
      <FormGroup bsSize="large">
        <FormControl
          value={passwordOne}
          onChange={event => this.setState(updateByPropertyName('passwordOne', event.target.value))}
          type="password"
          placeholder="Password"
        />
      </FormGroup>
      <FormGroup bsSize="large">
        <FormControl 
          value={passwordTwo}
          onChange={event => this.setState(updateByPropertyName('passwordTwo', event.target.value))}
          type="password"
          placeholder="Confirm Password"
        />
      </FormGroup>
        <Button bsStyle="primary" bsSize="large" className="btn-blank btn-blue" disabled={isInvalid} type="submit">
          Sign Up
        </Button>

        { error && <p>{error.message}</p> }
      </form>
      </FormGroup>
    );
  }
}

const RequestAccessLink = () =>
  <p>
    Don't have an account?
    {' '}
    <Link to={routes.REQUEST_ACCESS}>Request Access</Link>
  </p>

export default withRouter(RequestAccess);

export {
  SignUpForm,
  RequestAccessLink,
};
