import React from 'react';

import { Grid, Col, Button } from 'react-bootstrap';

const SalesCallToAction = () => {
  return (
    <div className="we-can-help-you">
      <Grid fluid>
        <Col>
          <center>
            <h1>We Can Help You</h1>
            <h3 className="sub-heading">Our sale representatives can help you quote your projects today.</h3>
            <a href="/contact"><Button className="btn-blank btn-blue">Contact Rep</Button><br/><br/></a>
            <p>(866) 596-7537</p>
          </center>
        </Col>
      </Grid>
    </div>
  )
}

export default SalesCallToAction;