import React from 'react';
import { Table } from 'react-bootstrap';

const FiveMeshToEightMesh = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 5 Mesh - 8 Mesh</h4>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA.</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>OPEN AREA %</h5></th>
            <th><h5>WEIGHT/SQFT</h5></th>
          </tr>
        </thead>

        <tbody>
        <tr>
          <th>5X5</th>
          <td>.0.32</td>
          <td>.168</td>
          <td>70.6</td>
          <td>.332</td>
          </tr>
          <tr>
          <th>5X5</th>
          <td>.0.35</td>
          <td>.165</td>
          <td>68.1</td>
          <td>.398</td>
          </tr>
          <tr>
          <th>5X5</th>
          <td>.041</td>
          <td>.159</td>
          <td>63.2</td>
          <td>.549</td>
          </tr>
          <tr>
          <th>5X5</th>
          <td>.047</td>
          <td>.153</td>
          <td>58.5</td>
          <td>.726</td>
          </tr>
          <tr>
          <th>5X5</th>
          <td>.054</td>
          <td>.146</td>
          <td>53.3</td>
          <td>.967</td>
          </tr>
          <tr>
          <th>5X5</th>
          <td>.063</td>
          <td>.137</td>
          <td>46.9</td>
          <td>1.332</td>
          </tr>
          <tr>
          <th>5X5</th>
          <td>.072</td>
          <td>.128</td>
          <td>41.0</td>
          <td>1.768</td>
          </tr>
          <tr>
          <th>5X5</th>
          <td>.080</td>
          <td>.120</td>
          <td>36.0</td>
          <td>2.206</td>
          </tr>
          <tr>
          <th>5X5</th>
          <td>.092</td>
          <td>.108</td>
          <td>29.2</td>
          <td>2.834</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.028</td>
          <td>.139</td>
          <td>69.6</td>
          <td>.305</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>0.32</td>
          <td>.135</td>
          <td>65.6</td>
          <td>.400</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.032</td>
          <td>.135</td>
          <td>65.6</td>
          <td>.400</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.035</td>
          <td>.132</td>
          <td>62.7</td>
          <td>.481</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.041</td>
          <td>.126</td>
          <td>57.2</td>
          <td>.665</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.047</td>
          <td>.120</td>
          <td>51.8</td>
          <td>.882</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.054</td>
          <td>.113</td>
          <td>46.0</td>
          <td>1.177</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.063</td>
          <td>.104</td>
          <td>38.9</td>
          <td>1.630</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.072</td>
          <td>.095</td>
          <td>32.5</td>
          <td>2.169</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.080</td>
          <td>.087</td>
          <td>27.2</td>
          <td>2.591</td>
          </tr>
          <tr>
          <th>6X6</th>
          <td>.092</td>
          <td>.075</td>
          <td>20.2</td>
          <td>3.528</td>
          </tr>
          <tr>
          <th>7X7</th>
          <td>.028</td>
          <td>.115</td>
          <td>64.8</td>
          <td>.358</td>
          </tr>
          <tr>
          <th>7X7</th>
          <td>.032</td>
          <td>.111</td>
          <td>60.4</td>
          <td>.470</td>
          </tr>
          <tr>
          <th>7X7</th>
          <td>.035</td>
          <td>.108</td>
          <td>57.2</td>
          <td>.565</td>
          </tr>
          <tr>
          <th>7X7</th>
          <td>.041</td>
          <td>.102</td>
          <td>51.0</td>
          <td>.784</td>
          </tr>
          <tr>
          <th>7X7</th>
          <td>.047</td>
          <td>.096</td>
          <td>45.2</td>
          <td>1.042</td>
          </tr>
          <tr>
          <th>7X7</th>
          <td>.054</td>
          <td>.089</td>
          <td>38.8</td>
          <td>1.397</td>
          </tr>
          <tr>
          <th>7X7</th>
          <td>.063</td>
          <td>.080</td>
          <td>31.4</td>
          <td>1.847</td>
          </tr>
          <tr>
          <th>8X8</th>
          <td>.020</td>
          <td>.105</td>
          <td>70.6</td>
          <td>.207</td>
          </tr>
          <tr>
          <th>8X8</th>
          <td>.023</td>
          <td>.102</td>
          <td>66.6</td>
          <td>.275</td>
          </tr>
          <tr>
          <th>8X8</th>
          <td>.025</td>
          <td>.100</td>
          <td>64.0</td>
          <td>.326</td>
          </tr>
          <tr>
          <th>8X8</th>
          <td>.028</td>
          <td>.097</td>
          <td>60.2</td>
          <td>.411</td>
          </tr>
          <tr>
          <th>8X8</th>
          <td>.035</td>
          <td>.090</td>
          <td>51.8</td>
          <td>.651</td>
          </tr>
          <tr>
          <th>8X8</th>
          <td>.041</td>
          <td>.084</td>
          <td>45.2</td>
          <td>.906</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default FiveMeshToEightMesh;
