import React from 'react';
import { Table } from 'react-bootstrap';

const BarGratingChart = () => {
  return (
    <div>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th><h5>BEARING BAR SIZE</h5></th>
            <th><h5>19-W-4</h5></th>
            <th><h5>19-W-2</h5></th>
            <th><h5>15-W-4</h5></th>
            <th><h5>15-W-2</h5></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <td>LBS SF</td>
            <td>LBS SF</td>
            <td>LBS SF</td>
            <td>LBS SF</td>
          </tr>
          <tr>
            <th>3/4&#8243; X 3/16&#8243;</th>
            <td>5.5</td>
            <td>6.0</td>
            <td>6.6</td>
            <td>7.3</td>
          </tr>
          <tr>
            <th>1&#8243; X 1/8&#8243;  </th>
            <td>4.9</td>
            <td>5.5</td>
            <td>5.9</td>
            <td>6.6</td>
          </tr>
          <tr>
            <th>1&#8243; X 3/16&#8243;</th>
            <td>7.1</td>
            <td>7.2</td>
            <td>8.6</td>
            <td>9.2</td>
          </tr>
          <tr>
            <th>1&#8243; X 1/4&#8243;</th>
            <td>9.2</td>
            <td>10.0</td>
            <td>11.5</td>
            <td>12.3</td>
          </tr>
          <tr>
            <th>1-1/4&#8243; X 1/8&#8243;</th>
            <td>6.0</td>
            <td>6.4</td>
            <td>7.2</td>
            <td>7.8</td>
          </tr>
          <tr>
            <th>1-1/4&#8243; X 3/16&#8243; </th>
            <td>8.7</td>
            <td>9.0</td>
            <td>10.4</td>
            <td>11.1</td>
          </tr>
          <tr>
            <th>1-1/4&#8243; X 1/4&#8243; </th>
            <td>11.4</td>
            <td>12.0</td>
            <td>14.1</td>
            <td>14.9 </td>
          </tr>
          <tr>
            <th>1-1/2&#8243; X 1/8&#8243; </th>
            <td>7.1</td>
            <td>7.5</td>
            <td>8.3</td>
            <td>9.1</td>
          </tr>
          <tr>
            <th>1-1/2&#8243; X 3/16&#8243;</th>
            <td>10.3</td>
            <td>10.6</td>
            <td>12.4</td>
            <td>13.0</td>
          </tr>
          <tr>
            <th>1-1/2&#8243; X 1/4&#8243;</th>
            <td>13.5</td>
            <td>14.2</td>
            <td>16.8</td>
            <td>17.6</td>
          </tr>
          <tr>
            <th>1-3/4&#8243; X 3/16&#8243;</th>
            <td>12.1</td>
            <td>13.2</td>
            <td>14.6</td>
            <td>15.4</td>
          </tr>
          <tr>
            <th>1-3/4&#8243; X 1/4&#8243; </th>
            <td>15.7 </td>
            <td>17.4 </td>
            <td>19.9</td>
            <td>20.8</td>
          </tr>
          <tr>
            <th>2&#8243; X 3/16&#8243;</th>
            <td>13.8</td>
            <td>14.8</td>
            <td>16.7</td>
            <td>17.5</td>
          </tr>
          <tr>
            <th>2&#8243; X 1/4&#8243;</th>
            <td>18.1</td>
            <td>19.1</td>
            <td>22.6</td>
            <td>23.7</td>
          </tr>
          <tr>
            <th>2-1/4&#8243; X 3/16&#8243;  </th>
            <td>14.8 </td>
            <td>15.7</td>
            <td>18.5 </td>
            <td>19.4</td>
          </tr>
          <tr>
            <th>2-1/4&#8243; X 1/4&#8243;</th>
            <td>20.0</td>
            <td>21.1</td>
            <td>25.4</td>
            <td>26.2</td>
          </tr>
          <tr>
            <th>2-1/2&#8243; X 3/16&#8243;</th>
            <td>17.0</td>
            <td>17.2</td>
            <td>20.5</td>
            <td>21.0</td>
          </tr>
          <tr>
            <th>2-1/2&#8243; X 1/4&#8243;</th>
            <td>22.1</td>
            <td>23.2</td>
            <td>28.1</td>
            <td>29.0</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default BarGratingChart;
