import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash'
import { addProduct } from '../../../../services/cart/actions';
import { Link } from 'react-router-dom'
import  defaultPhotoURL from '../../../../img/default_avatar.jpg';

const Product = ({ product, addProduct }) => {
  product.quantity = 1;
  const { product_type_name, productAlloy, productWire, productDims, productOpn, id, img_url, productName, description, currencyFormat, price} = product;

  const productImage = img_url ? (
    <div style={{ height: 200, overflow: 'hidden', borderTopRightRadius: 22, borderTopLeftRadius: 22,}}>
      <img src={img_url} alt="product" className="card-img-container" />
    </div>
    ) : (
    <div style={{ height: 200, overflow: 'hidden', borderTopRightRadius: 22, borderTopLeftRadius: 22,}}>
      <img src={defaultPhotoURL} alt="product" className="card-img-container" />
    </div>
  )

  return (
  <Link to={`/store/product/${id}`}>
    <div className="list-card-style">


        <div>

          {productImage}

        </div>

        <div className="list-card" style={{flexDirection: 'row', minHeight: 120}}>
          <div style={{width: '100%'}}>
              
            <div style={{padding: 4}}>
            
              <div className="val">
                <p style={{color: '#212121', fontSize: 12, fontWeight: 'bold'}}>{_.toUpper(product_type_name)}</p>
              </div>
              <div className="val">
                <p style={{color: '#212121', fontSize: 12, fontWeight: 'bold'}}>{_.toUpper(productName)}</p>
              </div>

              {/* <p style={{fontSize: 18, fontWeight: '900', color: '#212121'}}>{description}</p> */}
              
              <p style={{fontSize: 12,}}>
                {description ? (
                  <>
                    <strong style={{color: '#212121', fontWeight: 900}}>{description}</strong> 
                  </>
                ) : (<></>)}
              </p>
              
              <div style={{display: 'flex', flexDirection: 'row',}}>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {productAlloy ? (
                      <>
                        <p style={{color: '#212121', fontWeight: 'bold'}}>Alloy</p><strong style={{color: '#999999', fontWeight: 900}}>{productAlloy}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {productOpn ? (
                      <>
                        <p style={{color: '#212121', fontWeight: 'bold'}}>Opn</p><strong style={{color: '#999999', fontWeight: 900}}>{productOpn}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {productWire ? (
                      <>
                        <p style={{color: '#212121', fontWeight: 'bold'}}>Wire</p><strong style={{color: '#999999', fontWeight: 900}}>{productWire}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
                <div style={{width: '100%'}}>
                  <p style={{fontSize: 12}}>
                    {productDims ? (
                      <>
                        <p style={{color: '#212121', fontWeight: 'bold'}}>Dims</p><strong style={{color: '#999999', fontWeight: 900}}>{productDims}</strong> 
                      </>
                    ) : (<></>)}
                  </p>
                </div>
              </div>

              <div>
                <div className="val">
                  <span><p style={{ color: '#212121', fontSize: 12, fontWeight: 900 }}> {currencyFormat} {price} <span style={{color: "#999999"}}>ea.</span></p></span>
                </div>
              </div>

            </div>
          </div>
        </div>

    </div>
  </Link>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired
};

export default connect(
  null,
  { addProduct }
)(Product);