import React from 'react';
import { Table } from 'react-bootstrap';

const ThreeFourthOfInchToHalfInch = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 3/4&#8243; Mesh - 1/2&#8243; OPN</h4>
      <Table striped condensed hover>

          <thead>
            <tr>
              <th><h5>MESH</h5></th>
              <th><h5>WIRE DIA.</h5></th>
              <th><h5>OPENING</h5></th>
              <th><h5>OPEN AREA %</h5></th>
              <th><h5>WEIGHT/SQFT</h5></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.063</td>
              <td>.687</td>
              <td>83.9</td>
              <td>.340</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.072</td>
              <td>.678</td>
              <td>81.7</td>
              <td>.445</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.080</td>
              <td>.670</td>
              <td>79.8</td>
              <td>.549</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.092</td>
              <td>.658</td>
              <td>76.9</td>
              <td>.728</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.105</td>
              <td>.645</td>
              <td>73.9</td>
              <td>.950</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.120</td>
              <td>.630</td>
              <td>70.5</td>
              <td>1.244</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.135</td>
              <td>.615</td>
              <td>67.2</td>
              <td>1.581</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.148</td>
              <td>.602</td>
              <td>64.4</td>
              <td>1.905</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.162</td>
              <td>.588</td>
              <td>61.4</td>
              <td>2.292</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.192</td>
              <td>.558</td>
              <td>55.3</td>
              <td>3.248</td>
            </tr>

            <tr>
              <th scope="row">3/4&#8243; X 3/4&#8243;</th>
              <td>.250</td>
              <td>.500</td>
              <td>44.4</td>
              <td>5.623</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.063</td>
              <td>.562</td>
              <td>80.9</td>
              <td>.409</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.072</td>
              <td>.553</td>
              <td>78.3</td>
              <td>.535</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.080</td>
              <td>.545</td>
              <td>76.0</td>
              <td>.661</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.092</td>
              <td>.533</td>
              <td>72.7</td>
              <td>.879</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.105</td>
              <td>.520</td>
              <td>69.2</td>
              <td>1.145</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.120</td>
              <td>.505</td>
              <td>65.3</td>
              <td>1.502</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.135</td>
              <td>.490</td>
              <td>61.5</td>
              <td>1.910</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.148</td>
              <td>.477</td>
              <td>58.3</td>
              <td>2.305</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.162</td>
              <td>.463</td>
              <td>54.9</td>
              <td>2.777</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.177</td>
              <td>.448</td>
              <td>51.4</td>
              <td>3.335</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.192</td>
              <td>.433</td>
              <td>48</td>
              <td>3.95</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.207</td>
              <td>.418</td>
              <td>44.7</td>
              <td>4.624</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243;X 5/8&#8243;</th>
              <td>.225</td>
              <td>.4</td>
              <td>41</td>
              <td>5.51</td>
            </tr>

            <tr>
              <th scope="row">5/8&#8243; X 5/8&#8243;</th>
              <td>.25</td>
              <td>.375</td>
              <td>36</td>
              <td>6.894</td>
            </tr>

            <tr>
              <th scope="row">1/2&#8243; OPN</th>
              <td>.063</td>
              <td>.5</td>
              <td>78.9</td>
              <td>.45</td>
            </tr>

            <tr>
              <th scope="row">1/2&#8243; OPN</th>
              <td>.072</td>
              <td>.5</td>
              <td>76.9</td>
              <td>.58</td>
            </tr>

            <tr>
              <th scope="row">1/2&#8243; OPN</th>
              <td>.08</td>
              <td>.5</td>
              <td>74.3</td>
              <td>.71</td>
            </tr>

            <tr>
              <th scope="row">1/2&#8243; OPN</th>
              <td>.092</td>
              <td>.5</td>
              <td>71.3</td>
              <td>.93</td>
            </tr>

            <tr>
              <th scope="row">1/2&#8243; OPN</th>
              <td>.105</td>
              <td>.5</td>
              <td>68.3</td>
              <td>1.18</td>
            </tr>

            <tr>
              <th scope="row">1/2&#8243; OPN</th>
              <td>.12</td>
              <td>.5</td>
              <td>65</td>
              <td>1.51</td>
            </tr>

            <tr>
              <th scope="row">1/2&#8243; OPN</th>
              <td>.135</td>
              <td>.5</td>
              <td>62</td>
              <td>1.88</td>
            </tr>

            <tr>
              <th scope="row">1/2&#8243; OPN</th>
              <td>.148</td>
              <td>.5</td>
              <td>59.5</td>
              <td>2.22</td>
            </tr>

          </tbody>
      </Table>
    </div>
  )
}

export default ThreeFourthOfInchToHalfInch;