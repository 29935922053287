import firebase from "firebase";


export const FETCH_PROFILE_PROPS = "FETCH_PROFILE_PROPS";

export function fetchUserProfileProps() {
  return dispatch => {
    
    firebase.auth().onAuthStateChanged(user => {
      // const { uid = null } = user;
      firebase.database().ref(`users/${user.uid}`).once("value").then(snapshot => {
          let payload = snapshot.val();
          
          console.log(payload, "SOME PAYLOAD")
          
          dispatch({
            type: FETCH_PROFILE_PROPS,
            payload
          })

        }).catch(err => console.log(err, "Something went wrong"))
    })
  }
}


