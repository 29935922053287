import React from 'react';
import { Table } from 'react-bootstrap';

const HardwareClothChart = () => {
  return (
    <div>
      <Table striped condensed hover>
        <thead>
          <tr>
            <th><h5>MESH</h5></th>
            <th><h5>WIRE DIA</h5></th>
            <th><h5>OPENING</h5></th>
            <th><h5>WEIGHT/SQ.FT</h5></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>1&#8243;</th>
            <td>0.08</td>
            <td>0.92</td>
            <td>0.41</td>

          </tr>

          <tr>
            <th>.1&#8243;</th>
            <td>0.063</td>
            <td>0.937</td>
            <td>0.26</td>

          </tr>

          <tr>
            <th>1&#8243;</th>
            <td>0.054</td>
            <td>0.946</td>
            <td>0.23</td>
          </tr>

          <tr>
            <th>3/4&#8243;</th>
            <td>0.051</td>
            <td>0.699</td>
            <td>0.13</td>
          </tr>

          <tr>
            <th>2 mesh</th>
            <td>0.063</td>
            <td>0.437</td>
            <td>0.51</td>
          </tr>

          <tr>
            <th>2 mesh</th>
            <td>0.054</td>
            <td>0.446</td>
            <td>0.46</td>
          </tr>

          <tr>
            <th>2 mesh</th>
            <td>0.041</td>
            <td>0.459</td>
            <td>0.22</td>
          </tr>

          <tr>
            <th>2 mesh</th>
            <td>0.035</td>
            <td>0.465</td>
            <td>0.2</td>
          </tr>

          <tr>
            <th>4 mesh</th>
            <td>0.025</td>
            <td>0.225</td>
            <td>0.16</td>
          </tr>

          <tr>
            <th>4 mesh</th>
            <td>0.021</td>
            <td>0.229</td>
            <td>0.14</td>
          </tr>

          <tr>
            <th>8 mesh</th>
            <td>0.017</td>
            <td>0.108</td>
            <td>0.16</td>
          </tr>

          <tr>
            <th>8 mesh</th>
            <td>0.014</td>
            <td>0.111</td>
            <td>0.11</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default HardwareClothChart;
