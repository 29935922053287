import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { firebase } from './firebase/firebase';
import configureStore from './services/store';
import { login, signInSuccess } from './actions/auth';
import { startUserProps } from  './actions/user'
import App from './components/app';
import 'normalize.css/normalize.css';
import './styles/index.scss';
import LoadingPage from './components/utilities/LoadingPage';
import Root from "./Root"

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <Root>
      <App />
    </Root>
  </Provider>
);

let hasRendered = false;

const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
    hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById('root'));

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch(login(user.uid));
    store.dispatch(startUserProps(user.uid))
    store.dispatch(signInSuccess(user.uid))
    renderApp();

      if (this.props.history.location.pathname === '/') {
        this.props.history.push('/dashboard');
      }
    
  } else {
    renderApp();
    this.props.history.push('/');
  }
});
