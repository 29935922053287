import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { Grid, Row, Col, Button } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';
import TieWireChart from './TieWire/TieWireChart';

import image1 from './../../img/product-img/tie_wire/TieWire1.jpg';
import image2 from './../../img/product-img/tie_wire/TieWire2.jpg';
import image3 from './../../img/product-img/tie_wire/TieWire.jpg';

const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <Col md={4}>
      <img src={image1} alt="TieWire1" draggable={false} className="img-fix" />
      <img src={image2} alt="TieWire2" draggable={false} className="img-fix" />
      <img src={image3} alt="TieWire" draggable={false} className="img-fix" />
      </Col>
      <Col md={8}>
        <h3>Tie Wire</h3>
        Available in <span className="label label-default">304SS</span> <span className="label label-default">316SS</span> <span className="label label-default">347SS</span> <span className="label label-default">INCONEL</span> <span className="label label-default">MONEL</span> <span className="label label-default"> HASTELLOY C </span><br/>
        Other specialty alloys are available upon request, please allow 3-5 days delivery for non-stocking alloys.<br/><br/>
        <h4>Stock in 5 lbs spools-25 lb coils:</h4>
        <TieWireChart />
      </Col>
    </div>
  )
}

const TieWire = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="tie-wire-header">
            <Grid>
              <h2>Tie Wire</h2>
              <h4>No job is too big or too small.</h4>
              <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_TIE_WIRE.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
            <MeasuringAndOrderingInstructions />
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default TieWire;