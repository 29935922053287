import React from 'react';
import { Table } from 'react-bootstrap';

const SafetyGratingChart = () => {
  return (
    <div>
      <Table striped condensed hover>

        <thead>
          <tr>
            <th><h5>Side Channels Available</h5></th>
            <th><h5>Gauge &amp Type Available</h5></th>
            <th><h5>Product Widths Available</h5></th>
          </tr>
        </thead>

        <tbody>

          <tr>
            <th>1 1/2&#8243;</th>
            <td>14 ga Galvanized Steel</td>
            <td>4 3/4&#8243;</td>

          </tr>

          <tr>
            <th>2&#8243;</th>
            <td>12 ga Galvanized Steel</td>
            <td>7&#8243;</td>
          </tr>

          <tr>
            <th>2 1/2&#8243;</th>
            <td>.080 Aluminum 5052-H32</td>
            <td>9 1/2&#8243;</td>
          </tr>

          <tr>
            <th>3"&#8243;</th>
            <td>.100 Aluminum 5052-H32</td>
            <td>11 3/4&#8243;</td>
          </tr>

          <tr>
            <th>4 1/2&#8243; (Walkway)</th>
            <td>*16 GA Type 304 Stainless</td>
            <td>18 3/4&#8243;</td>
          </tr>

          <tr>
            <th>STANDARD LENGTHS - 10' & 12'</th>
            <td>1/8&#8243;</td>
            <td>20-16</td>
          </tr>

          <tr>
            <th>3/32&#8243;</th>
            <td>Plain HRP & O Steel available upon request and special quotation.<br />
              Type 316 Stainless available upon request and special quotation.<br />
              Special fabrication cutting or specialty configurations available upon request and special quoatation.<br />
            </td>
            <td></td>
          </tr>

        </tbody>
      </Table>
    </div>
  )
}

export default SafetyGratingChart;
