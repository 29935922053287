export const formatPrice = (x, currency) => {
  switch (currency) {
    case 'BRL':
      return x.toFixed(2).replace('.', ',');
    default:
      return x.toFixed(2);
  }
};

// export const productsAPI =
  // 'https://react-shopping-cart-67954.firebaseio.com/products.json';
export const productsAPI = "http://localhost:3001/api/products";
// "https://firebasestorage.googleapis.com/v0/b/okbuds-3bc7d.appspot.com/o/assets%2Fweed_4.png?alt=media&token=3a934a55-e72d-408e-8b01-86c36bcc5a12"