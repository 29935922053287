import React from 'react';
import { Table } from 'react-bootstrap';

const TieWireChart = () => {
  return (
    <div>
      <Table striped condensed hover>
        <h5>Measuring and Ordering Material</h5>
        <tbody>
          <tr>
            <td>.035&#8243; Diameter 20GA</td>
            <td>.120&#8243; Diameter 11GA</td>
          </tr>

          <tr>
            <td>.041&#8243; Diameter 19GA</td>
            <td>.135&#8243; Diameter 10GA</td>
          </tr>

          <tr>
            <td>.054&#8243; Diameter 17GA</td>
            <td>.148&#8243; Diameter 9GA</td>
          </tr>

          <tr>
            <td>.063&#8243; Diameter 16GA</td>
            <td>.162" Diameter 8GA</td>
          </tr>

          <tr>
            <td>.072&#8243; Diameter 15GA</td>
            <td>.177&#8243; Diameter 7GA</td>
          </tr>

          <tr>
            <td>.080&#8243; Diameter 14GA</td>
            <td>.192&#8243; Diameter 6GA</td>
          </tr>

          <tr>
            <td>.092&#8243; Diameter 13GA</td>
            <td>.225&#8243;  Diameter 4GA</td>
          </tr>

          <tr>
            <td>.105&#8243; Diameter 12GA</td>
            <td>.250&#8243; Diameter 3GA</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default TieWireChart;
