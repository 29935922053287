import React from "react";
import { connect } from 'react-redux';
import Layout from "../layout/Layout";
import { startAddProduct } from '../../actions/products';
import { Grid } from "react-bootstrap"
import {Helmet} from "react-helmet";
import ProductForm from './ProductForm';
// Style sheet
import "../../styles/App.css";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Woven Wire Mesh | Welded Wire Mesh | Perf Metal and More | Presicion Screen Manufacturing</title>
    <link rel="canonical" href="http://psm-mfg.com" />
  </Helmet>
)


export class NewProductView extends React.Component {
  onSubmit = (product) => {
    this.props.startAddProduct(product);
    this.props.history.push('/');
  };
  render() {
    return (
      <Layout>
        <HeaderTags/>
        <Grid>
          <div style={{ marginBottom: 80 }}>
            <ProductForm
              onSubmit={this.onSubmit}
            />
          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddProduct: (product) => dispatch(startAddProduct(product))
});

export default connect(undefined, mapDispatchToProps)(NewProductView);