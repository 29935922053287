import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { Grid, Row, Col, Button } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';
import HardwareClothChart from './HardwareCloth/HardwareClothChart';

import image1 from './../../img/product-img/hardware_cloth/HardwareCloth1.jpg';
import image2 from './../../img/product-img/hardware_cloth/HardwareCloth2.jpg';
import image3 from './../../img/product-img/hardware_cloth/HardwareCloth3.jpg';
import image4 from './../../img/product-img/hardware_cloth/HardwareCloth4.jpg';

const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <Col md={4}>
        <img src={image1} draggable={false} alt="Sharp white rectangular mesh with blue blurry background." className="img-fix" />
        <img src={image2} draggable={false} alt="HardwareCloth1" className="img-fix" />
        <img src={image3} draggable={false} alt="HardwareCloth4" className="img-fix" />
        <img src={image4} draggable={false} alt="HardwareCloth3" className="img-fix" />
      </Col>
      <Col md={8}>
        <h3>Stocked Hardware Mesh</h3>
        <p> We can source most specifications upon request. Some people may also know this as chicken wire,
            or chicken netting, bug screen, and hail guards. We also offer hex netting as well as square openings.</p>
        <h3>Measuring and Ordering Material</h3>
        <HardwareClothChart />
      </Col>
    </div>
  )
}

const HardwareCloth = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="hardware-cloth-header">
            <Grid>
              <h2>Hardware Cloth</h2>
              <h4>We stock and offer 36″, 48″ and 60″ on most sizes.</h4>
              <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_HARDWARE_CLOTH.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
            <MeasuringAndOrderingInstructions />
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default HardwareCloth;