import React from 'react';
import { Table } from 'react-bootstrap';

const ThreeEightsToThreeSixteenth = () => {
  return (
    <div>
      <h4>Woven Wire Mesh 3/8&#8243; OPN - 3/16&#8243; OPN</h4>
      <Table striped condensed hover>

          <thead>
            <tr>
              <th><h5>MESH</h5></th>
              <th><h5>WIRE DIA.</h5></th>
              <th><h5>OPENING</h5></th>
              <th><h5>OPEN AREA %</h5></th>
              <th><h5>WEIGHT/SQFT</h5></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th>3/8&#8243; OPN</th>
              <td>1.92</td>
              <td>.375</td>
              <td>43.8</td>
              <td>4.39</td>

            </tr>

            <tr>
              <th>3/8&#8243; OPN</th>
              <td>.209</td>
              <td>.375</td>
              <td>41.5</td>
              <td>5</td>

            </tr>

            <tr>
              <th>3/8&#8243; OPN</th>
              <td>.225</td>
              <td>.375</td>
              <td>39</td>
              <td>5.77</td>
            </tr>

            <tr>
              <th>3/8&#8243; OPN</th>
              <td>.25</td>
              <td>.375</td>
              <td>36</td>
              <td>6.89</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.054</td>
              <td>.3125</td>
              <td>72.7</td>
              <td>51</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.063</td>
              <td>.3125</td>
              <td>69.3</td>
              <td>.69</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.072</td>
              <td>.3125</td>
              <td>66.1</td>
              <td>.88</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.08</td>
              <td>.3125</td>
              <td>63.4</td>
              <td>1.07</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.092</td>
              <td>.3125</td>
              <td>59.6</td>
              <td>1.37</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.105</td>
              <td>.3125</td>
              <td>56</td>
              <td>1.74</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.12</td>
              <td>.3125</td>
              <td>52.2</td>
              <td>2.21</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.135</td>
              <td>.3125</td>
              <td>48.8</td>
              <td>2.72</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.148</td>
              <td>.3125</td>
              <td>46</td>
              <td>3.2</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.162</td>
              <td>.3125</td>
              <td>43.4</td>
              <td>3.74</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.177</td>
              <td>.3125</td>
              <td>40.8</td>
              <td>4.36</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.192</td>
              <td>.3125</td>
              <td>38.4</td>
              <td>5</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.207</td>
              <td>.3125</td>
              <td>36.2</td>
              <td>5.68</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.225</td>
              <td>.3125</td>
              <td>33.8</td>
              <td>6.53</td>
            </tr>

            <tr>
              <th>5/16&#8243; OPN</th>
              <td>.25</td>
              <td>.3125</td>
              <td>30.9</td>
              <td>7.78</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.047</td>
              <td>.25</td>
              <td>70.9</td>
              <td>.48</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.054</td>
              <td>.25</td>
              <td>67.6</td>
              <td>.62</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.063</td>
              <td>.25</td>
              <td>63.8</td>
              <td>.83</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.072</td>
              <td>.25</td>
              <td>60.3</td>
              <td>1.06</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.08</td>
              <td>.25</td>
              <td>57.4</td>
              <td>1.28</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.092</td>
              <td>.25</td>
              <td>53.4</td>
              <td>1.64</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.105</td>
              <td>.25</td>
              <td>49.6</td>
              <td>2.07</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.12</td>
              <td>.25</td>
              <td>45.6</td>
              <td>2.62</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.135</td>
              <td>.25</td>
              <td>42.2</td>
              <td>3.21</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.148</td>
              <td>.25</td>
              <td>39.4</td>
              <td>3.76</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.162</td>
              <td>.25</td>
              <td>36.8</td>
              <td>4.38</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.177</td>
              <td>.25</td>
              <td>34.3</td>
              <td>5.08</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.192</td>
              <td>.25</td>
              <td>32</td>
              <td>5.82</td>
            </tr>

            <tr>
              <th>1/4&#8243; OPN</th>
              <td>.041</td>
              <td>.187</td>
              <td>67.3</td>
              <td>.48</td>
            </tr>
          </tbody>
      </Table>
    </div>
  )
}

export default ThreeEightsToThreeSixteenth;