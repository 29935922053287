import React from 'react';

const LoadingPage = () => (
  <div className="loader">
    <div>
      <img className="loader__image" src="/images/loader.gif" alt="loading" />
    </div>
  </div>
);

export default LoadingPage;
