import React from 'react';
import Layout from '../layout/Layout';
import { Grid } from 'react-bootstrap';

const PageNotFound = () => {
    return(
      <div> 
        <Layout>
          <Grid>
            <h1>NOT FOUND </h1>
          </Grid>
        </Layout>
        </div>
    )
  }

export default PageNotFound;