import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { Grid, Row, Col, Button } from 'react-bootstrap';

import PerforatedMetalChart from './PerforatedMetal/PerforatedMetalChart'
// Product Page Style
import './../../styles/products-style.css';

import image1 from './../../img/product-img/perforated_metal/PerforatedMetal1.jpg';
import image2 from './../../img/product-img/perforated_metal/PerforatedMetal2.jpg';
import image4 from './../../img/product-img/perforated_metal/PerforatedMetal4.jpg';
import image3 from './../../img/product-img/perforated_metal/PerforatedMetal3.jpg';

const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <Col md={4}>
        <img src={image1} alt="PerforatedMetal1" draggable={false} className="img-fix" />
        <img src={image2} alt="PerforatedMetal2" draggable={false} className="img-fix" />
        <img src={image3} alt="Abstract background of perforated metal with very shallow depth" draggable={false} className="img-fix" />
        <img src={image4} alt="PerforatedMetal4" draggable={false} className="img-fix" />
      </Col>
      <Col md={8}>
        <h3>Perforated Metal</h3>
        We are proud to offer perforated metal from the stock list or custom perforate to your requirements
        with the fastest lead times in the industry! No job is too big or too small.
          We can also custom perforate alloys such as <span className="label label-default">321SS</span>&nbsp;<span className="label label-default">317LSS</span>&nbsp;
          <span className="label label-default">Monel</span>&nbsp;<span className="label label-default">Hastelloy C</span> &nbsp;
          <span className="label label-default">Inconel</span>&nbsp;<span className="label label-default">400 series stainless</span>&nbsp;etc.
          <br /> <br />We stock mild steel and <span className="label label-default"> 304/316 stainless </span>
        &nbsp; in most items, standard stock size is <span className="label label-default"> 4' x 10' </span> &nbsp;
        items not in stock are available in 3-10 days from either of our stocking warehouses. Non-standard sizes and alloys are also available;
        availability is based on quantity, size, and material. We also are capable of decorative patterns as well.
        Please contact our sales staff to help meet your needs.

          <h3>Measuring and Ordering Material</h3>

        1. Specify opening (hole size), centers, open area, pattern and desired alloy.<br />
        2. Specify thickness of material, overall dimensions of material, and quantity required.<br />
        3. Please specify margins if required.<br />

        PSM Perforated Stock List
            Most patterns available <span className="label label-default">304SS</span> &nbsp;
            <span className="label label-default">316SS</span> &nbsp;
            <span className="label label-default">Carbon Steel</span> &nbsp;
            <span className="label label-default">Aluminum</span><br /><br />
        <strong>We can offer custom patterns upon request</strong>
        <PerforatedMetalChart />
      </Col>
    </div>
  )
}

const PerforatedMetal = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="perforated-metal-header">
            <Grid>
              <h2>Perforated Metal</h2>
              <h4>No job is too big or too small.</h4>
              <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_PERFORATED_METAL.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
            <MeasuringAndOrderingInstructions />
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default PerforatedMetal;