import React from 'react';

import { Grid, Col, Button } from 'react-bootstrap';

const AggregateCallToAction = () => {
  return (
    <div className="aggregate-cta-container">
      <Grid>
        <Col>
          <div>
            <h1 style={{color: '#FFFFFF'}}>Now serving the aggregate industry</h1>
            <h3 className="sub-heading" style={{color: '#FFFFFF'}} >Our sale representatives can help you quote your projects today.</h3>
            <a href="/contact"><Button className="btn-blank btn-blue">Contact Rep</Button><br/><br/></a>
          </div>
        </Col>
      </Grid>
    </div>
  )
}

export default AggregateCallToAction;