import { combineReducers } from 'redux';
import shelfReducer from './shelf/reducer';
import cartReducer from './cart/reducer';
import totalReducer from './total/reducer';
import filtersReducer from './filters/reducer';
import sortReducer from './sort/reducer';
import session from '../reducers/session'
import user from '../reducers/user';
import profile from '../reducers/profile';
import products from '../reducers/products';

export default combineReducers({
  shelf: shelfReducer,
  cart: cartReducer,
  total: totalReducer,
  filters: filtersReducer,
  sort: sortReducer,
  sessionState: session,
  // userState: user,
  products,
  profileState: profile,
  user
  
});
