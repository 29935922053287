import React from 'react';
import Layout from '../layout/Layout';

import { Grid, Row, Button } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';

const Materials = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="materials-header">
            <Grid>
              <h2>Materials</h2>
              <h4>Capabilities to weave all grades of stainless steel.</h4>
              <Button>Quote Project</Button>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
            <p>We weave all grades of stainless steel, most exotic alloys for the refining/petrochem industry,
              galvanized wire, aluminum, and other products used for separating and screening.
              Largest range of custom mesh specifications in all alloys from Plain Steel, Galvanized,
             304L/316L, 317, 321, 310, 330, 347, Monel, Inconel.</p>
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default Materials;