import React from 'react';
import Layout from '../layout/Layout';
import { Link } from "react-router-dom"
import { Grid, Row, Col, Button } from 'react-bootstrap';

// Product Page Style
import './../../styles/products-style.css';

import SafetyGratingChart from './SafetyGrating/SafetyGratingChart'

import image1 from './../../img/product-img/safety_grating/SafetyGrating2.jpg';
import image2 from './../../img/product-img/safety_grating/SafetyGrating1.jpg';
import image3 from './../../img/product-img/safety_grating/SafetyGrating3.jpg';

const MeasuringAndOrderingInstructions = () => {
  return (
    <div>
      <Col md={4}>
        <img src={image1} alt="SafetyGrating2" draggable={false} className="img-fix" />
        <img src={image2} alt="SafetyGrating1" draggable={false} className="img-fix" />
        <img src={image3} alt="SafetyGrating3" draggable={false} className="img-fix" />
      </Col>
      <Col md={8}>
        <h3>Safety Grating</h3>
        <p>Safety Grating is manufactured with an increased open area and greater traction causing better slip resistance to prevent hazards.
          Mud, snow, grease, debris can easily fall thru preventing slippage.
          Safety Grating is available in different widths, lengths, and heights to suit your requirements.
          The standard length is 10′ and 12′, custom lengths are available and typically take 2-3 week lead time for custom fabrication.
          In addition, we offer ladder rung and dimple plate. As always, we can offer custom fabricated sizes to meet your safety grating needs.</p>
        <h3>Measuring and Ordering Material</h3>
        <p>1. Alloy desired (SS, Alum,Galvanized, Plain Steel).</p>
        <p>2. Gauge of material and channel height.</p>
        <p>3. Dimensions of panels.</p>
        <SafetyGratingChart />
      </Col>
    </div>
  )
}

const SafetyGrating = () => {
  return (
    <div>
      <Layout>
        <Grid fluid>
          <div className="safety-grating-header">
            <Grid>
              <h2>Safety Grating</h2>
              <h4>We offer diamond channel as well as round channel grating.</h4>
              <Link to="/contact"><Button className="btn-blank btn-blue">QUOTE PROJECT</Button></Link>
              <a href="https://s3.us-east-2.amazonaws.com/psm-webapp/downloads/PSM_SAFETY_GRATING.pdf" target="blank">
                <Button className="btn-blank btn-gray">DOWNLOAD BOOK</Button>
              </a>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Row className="show-grid">
            <MeasuringAndOrderingInstructions />
          </Row>
        </Grid>
      </Layout>
    </div>
  )
}

export default SafetyGrating;